import React, { useContext, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { UserContext } from "../../contexts/UserContext";
import Header from "../../components/Header";
import { getValidationsByAnalysis } from "../../lib/firebase/validations";
import Moment from "react-moment";
import { ArrowLeft, CheckCircle, ExclamationTriangleFill, Eye } from "react-bootstrap-icons";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useParams, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { getAnalysisById } from "../../lib/firebase/analysis";
import { capitalizeString } from "../../utils/helpers";

/**
 * Validations component displays all validations for a specific analysis
 * It follows the functional programming approach with early returns
 */
function Validations() {
  const { user } = useContext(UserContext) as any;
  const [validations, setValidations] = useState<any[]>([]);
  const [analysis, setAnalysis] = useState<any>(null);
  const [loading, setLoading] = useState(true);
  const { analysisId } = useParams() as { analysisId: string };

  const { t } = useTranslation("Validations.index");

  /**
   * Fetch validations and analysis data
   */
  const fetchData = React.useCallback(async () => {
    if (!user?.firebaseUser?.uid || !analysisId) {
      setLoading(false);
      return;
    }

    try {
      setLoading(true);

      // Get validations for this analysis
      const validationsData = await getValidationsByAnalysis(user.firebaseUser.uid, analysisId);
      console.log(validationsData);
      setValidations(validationsData);

      // Get analysis details
      const analysisData = await getAnalysisById(user.firebaseUser.uid, analysisId);
      setAnalysis(analysisData);
    } catch (err: unknown) {
      if (err instanceof Error) {
        toast.error(t("errors.fetchFailed") + ": " + err.message);
      } else {
        toast.error(t("errors.unknown"));
      }
    } finally {
      setLoading(false);
    }
  }, [analysisId, user?.firebaseUser?.uid, t]);

  // Fetch data on component mount
  useEffect(() => {
    fetchData();
  }, [fetchData]);

  /**
   * Get the appropriate badge class based on validation status
   */
  const getStatusBadgeClass = (status: string): string => {
    switch (status) {
      case "processing":
        return "warning";
      case "error":
        return "danger";
      case "completed":
        return "success";
      default:
        return "secondary";
    }
  };

  /**
   * Get the appropriate status label based on validation status
   */
  const getStatusLabel = (status: string): string => {
    switch (status) {
      case "processing":
        return t("statusProcessing");
      case "error":
        return t("statusError");
      case "completed":
        return t("statusFinished");
      default:
        return status;
    }
  };

  if (loading) {
    return (
      <div className="d-flex justify-content-center align-items-center" style={{ height: "400px" }}>
        <div className="spinner-border text-primary" role="status">
          <span className="visually-hidden"></span>
        </div>
      </div>
    );
  }

  return (
    <div>
      <Header
        title={t("title")}
        subtitle={t("subtitle", { analysisName: analysis?.name })}
        breadCrumbItems={[
          { title: t("analysis"), href: "/analysis" },
          { title: analysis?.name, href: `/analysis/${analysis?.id}` },
          { title: t("validations") },
        ]}
      />
      <div className="card">
        <div className="card-header">
          <h4 className="card-header-title">{t("validations")}</h4>
        </div>
        <div className="card-body p-0">
          <div className="table-responsive datatable-custom" style={{ maxHeight: 450 }}>
            <table className="table table-lg table-borderless table-thead-bordered table-nowrap table-align-middle card-table dataTable no-footer">
              {validations.length > 0 && (
                <thead className="thead-light font-weight-light" style={{ position: "sticky", top: 0, zIndex: 1 }}>
                  <tr>
                    <th></th>
                    <th>{t("id")}</th>
                    <th>{t("provider")}</th>
                    <th>{t("status")}</th>
                    <th>{t("createdAt")}</th>
                    <th>{t("finishedAt")}</th>
                    <th>{t("statistics")}</th>
                    <th>{t("actions")}</th>
                  </tr>
                </thead>
              )}
              <tbody>
                {validations.length === 0 ? <NoValidations /> : null}

                {validations.map((item: any, index: number) => {
                  const errorTooltip = (
                    <Tooltip id={`tooltip-${index}`}>
                      <div>{item.errorMessage || t("errors.unknown")}</div>
                    </Tooltip>
                  );

                  return (
                    <tr key={index}>
                      <td className="text-center">
                        {item.status === "completed" ? (
                          <CheckCircle className="text-success" />
                        ) : item.status === "error" ? (
                          <ExclamationTriangleFill className="text-danger" />
                        ) : (
                          <div className="spinner-border spinner-border-sm text-warning" role="status">
                            <span className="visually-hidden"></span>
                          </div>
                        )}
                      </td>
                      <td>{item.id}</td>
                      <td>{capitalizeString(item.provider || "")}</td>
                      <td>
                        <span className={`badge bg-soft-${getStatusBadgeClass(item.status)} text-${getStatusBadgeClass(item.status)}`}>
                          {getStatusLabel(item.status)}
                        </span>{" "}
                        {item.status === "error" && item.errorMessage && (
                          <OverlayTrigger placement="top" overlay={errorTooltip}>
                            <ExclamationTriangleFill size="12" className="text-danger" />
                          </OverlayTrigger>
                        )}
                      </td>
                      <td>
                        {item.createdAt && (
                          <Moment format="DD/MM/YYYY HH:mm">
                            {typeof item.createdAt.toDate === "function" ? item.createdAt.toDate() : item.createdAt}
                          </Moment>
                        )}
                      </td>
                      <td>
                        {item.finishedAt && (
                          <Moment format="DD/MM/YYYY HH:mm">
                            {typeof item.finishedAt.toDate === "function" ? item.finishedAt.toDate() : item.finishedAt}
                          </Moment>
                        )}
                      </td>
                      <td>
                        {item.statistics ? (
                          <div>
                            <span className="badge bg-soft-success text-success mr-1">
                              {t("valid")}: {item.statistics.valid || 0}
                            </span>
                            <span className="badge bg-soft-danger text-danger mr-1">
                              {t("invalid")}: {item.statistics.invalid || 0}
                            </span>
                            <span className="badge bg-soft-warning text-warning">
                              {t("unknown")}: {item.statistics.unknown || 0}
                            </span>
                          </div>
                        ) : (
                          "-"
                        )}
                      </td>
                      <td>
                        <Link to={`/analysis/${analysisId}/validations/${item.id}`} className="btn btn-white btn-sm">
                          <Eye />
                          <span className="align-middle ml-1">{t("view")}</span>
                        </Link>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

/**
 * NoValidations component displayed when there are no validations
 */
function NoValidations() {
  const { t } = useTranslation("Validations.index");
  const { analysisId } = useParams() as { analysisId: string };

  return (
    <tr>
      <td colSpan={8}>
        <div className="card-body py-4">
          <div className="row align-items-center py-4">
            <div className="col text-center">
              <img src="/assets/svg/illustrations/oc-empty-cart.svg" alt="Empty" className="img-fluid mb-4" style={{ maxWidth: "10rem" }} />
              <p className="mb-0 text-center mb-4">{t("noValidations.message")}</p>
              <Link to={`/analysis/${analysisId}`} className="btn btn-primary">
                <span className="mr-1">
                  <ArrowLeft size="20" />
                </span>
                <span className="align-middle">{t("noValidations.goBack")}</span>
              </Link>
            </div>
          </div>
        </div>
      </td>
    </tr>
  );
}

export default Validations;
