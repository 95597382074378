import React, { useContext, useRef } from "react";
import { ChevronLeft, ChevronRight, InfoCircleFill } from "react-bootstrap-icons";
import { Link, useNavigate } from "react-router-dom";
import { WizardContext } from "../../contexts/WizardContext";
import { Button, ButtonGroup, Dropdown, DropdownButton, Overlay, Tooltip } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import ImpersonateStrip from "../../components/ImpersonateStrip";
import LanguageSwitcher from "../../components/LanguageSwitcher";

function Wrapper({ children, step, title, subtitle, cardWidth, beforeNext, disabled, loading, focus, newTab, skippable }: any) {
  const isFirstStep = step === 1;
  const isLastStep = step === 5;
  const target = useRef(null);
  const navigate = useNavigate();
  const { updateWizard } = useContext(WizardContext) as any;
  const { t } = useTranslation("Wizard.Wrapper");

  const handleNextStep = async () => {
    if (beforeNext) {
      try {
        await beforeNext();
      } catch (err) {
        console.error(err);
        return;
      }
    }

    if (!isLastStep) {
      await updateWizard({
        currentStep: step + 1,
      });
    } else {
      await updateWizard({
        completed: true,
        completedAt: new Date(),
      });
    }

    if (newTab) {
      if (!isLastStep) window.open(`/onboarding/step/${step + 1}`, "_blank");
      else window.open(`/?success=true`, "_blank");
    } else {
      if (!isLastStep) navigate(`/onboarding/step/${step + 1}`);
      else navigate(`/?success=true`);
    }
  };

  return (
    <>
      <ImpersonateStrip />
      <div className="bg-light d-flex justify-content-center align-items-center py-6" style={{ minHeight: "100vh" }}>
        <div className="container">
          <div className="text-center mb-0">
            <img className="img-fluid" alt="Logo" src="/assets/img/logos/mailerfind.png" style={{ width: "10rem" }} />
            <div className="mx-auto mt-4" style={{ maxWidth: "30rem" }}>
              <Steps step={step} />
            </div>
          </div>
          <div className="card mx-auto" style={{ maxWidth: cardWidth || "40rem" }}>
            <div className="card-body p-5">
              <h1 className="h2 text-center">{title}</h1>
              <p className="text-center mb-5">{subtitle}</p>
              {children}
              <div className="d-flex justify-content-between mt-4">
                <Link to={`/onboarding/step/${step - 1}`}>
                  <button className="btn btn-white" disabled={isFirstStep}>
                    <ChevronLeft className="mr-2" />
                    <span className="align-middle">{t("previous")}</span>
                  </button>
                </Link>

                <ButtonGroup>
                  <Button
                    ref={target}
                    onClick={handleNextStep}
                    className={`btn btn-primary ${focus && "micro"}`}
                    disabled={disabled || loading}
                  >
                    {loading ? (
                      <div className="px-6">
                        <div className="spinner-border spinner-border-sm align-middle" role="status">
                          <span className="sr-only">{t("loading")}</span>
                        </div>
                      </div>
                    ) : (
                      <>
                        <span className="align-middle">{t("next")}</span> <ChevronRight className="ml-2" />
                      </>
                    )}
                  </Button>
                  {skippable && (
                    <DropdownButton as={ButtonGroup} title="" id="bg-nested-dropdown">
                      <Dropdown.Item eventKey="2" className="px-3" onClick={handleNextStep}>
                        <span className="px-0">{t("skipThisStep")}</span>
                      </Dropdown.Item>
                    </DropdownButton>
                  )}
                </ButtonGroup>

                <Overlay target={target.current} show={step === 2 && !disabled} placement="left">
                  {(props) => (
                    <Tooltip id="overlay-example" {...props}>
                      <InfoCircleFill className="align-middle mr-1" /> <span className="align-middle">{t("infoMessage")}</span>
                    </Tooltip>
                  )}
                </Overlay>
              </div>
            </div>
          </div>
          <LanguageSwitcher style={{ position: "fixed", left: 20, bottom: 20 }} />
        </div>
      </div>
    </>
  );
}

function Steps({ step }: { step: number }) {
  return (
    <ul className="step step-md mb-4">
      <li className="step-item">
        <div className="step-content-wrapper">
          <span className={`step-icon ${step === 1 ? "border-primary step-icon-soft-primary" : "step-icon-soft-secondary"}`}>1</span>
        </div>
      </li>
      <li className="step-item">
        <div className="step-content-wrapper">
          <span className={`step-icon ${step === 2 ? "border-primary step-icon-soft-primary" : "step-icon-soft-secondary"}`}>2</span>
        </div>
      </li>
      <li className="step-item">
        <div className="step-content-wrapper">
          <span className={`step-icon ${step === 3 ? "border-primary step-icon-soft-primary" : "step-icon-soft-secondary"}`}>3</span>
        </div>
      </li>
      <li className="step-item">
        <div className="step-content-wrapper">
          <span className={`step-icon ${step === 4 ? "border-primary step-icon-soft-primary" : "step-icon-soft-secondary"}`}>4</span>
        </div>
      </li>
    </ul>
  );
}

export default Wrapper;
