import { BACKEND_URL } from "../../utils/constants";
import makeRequest from "../client";

export const startAnalysis = async (analysisId: string, jwt: string): Promise<any> => {
  const response = await makeRequest({
    url: `${BACKEND_URL}/v1/analysis/${analysisId}/start`,
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${jwt}`,
    },
  });

  return response;
};

export const pauseAnalysis = async (analysisId: string, jwt: string): Promise<any> => {
  const response = await makeRequest({
    url: `${BACKEND_URL}/v1/analysis/${analysisId}/pause`,
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${jwt}`,
    },
  });

  return response;
};

/**
 * Lanza una petición al backend para validar los correos electrónicos asociados a un análisis concreto.
 * Esta validación se realiza mediante un proveedor externo, como por ejemplo MillionVerifier.
 *
 * @param {string} analysisId - ID del análisis previamente creado que se desea validar.
 * @param {string} provider - Nombre del proveedor externo que realizará la validación (ej: 'millionverifier').
 * @param {string} jwt - Token JWT de autenticación del usuario.
 * @returns {Promise<any>} - Promesa que resuelve con la respuesta del backend, normalmente un objeto con los resultados de la validación.
 */
export const validateAnalysis = async (analysisId: string, provider: string, jwt: string): Promise<any> => {
  const response = await makeRequest({
    url: `${BACKEND_URL}/v1/analysis/${analysisId}/validate`,
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${jwt}`,
    },
    body: {
      provider: provider,
    },
  });

  return response;
};

/**
 * Lanza una petición al backend para exportar los resultados de un análisis en un formato concreto
 * (por ejemplo, CSV o Excel), aplicando filtros y subfiltros específicos.
 *
 * @param {string} analysisId - ID del análisis cuyos resultados se desean exportar.
 * @param {any} filters - Filtros generales aplicados a los resultados (ej: emails válidos, únicos, etc.).
 * @param {any} subfilters - Subfiltros más específicos dentro de cada filtro (ej: dominios concretos, calidad, etc.).
 * @param {string} format - Formato de exportación (por ejemplo: 'csv', 'xlsx').
 * @param {string} jwt - Token JWT de autenticación del usuario.
 * @returns {Promise<any>} - Promesa que resuelve con la respuesta del backend, que puede incluir un enlace de descarga o el fichero directamente.
 */
export const exportAnalysis = async (analysisId: string, filters: any, subfilters: any, format: string, jwt: string) => {
  const response: any = await makeRequest({
    url: `${BACKEND_URL}/v1/analysis/${analysisId}/export`,
    method: "POST",
    body: {
      analysisId,
      format,
      filters,
      subfilters,
    },
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${jwt}`,
    },
  });
  return response;
};
