import React, { useContext, useEffect, useState } from "react";
import toast from "react-hot-toast";
import Flag from "react-world-flags";
import { UserContext } from "../../contexts/UserContext";
import Billing from "./billing";
import { useTranslation } from "react-i18next";
import OrderHistory from "./OrderHistory";
import { DEFAULT_LANGUAGE, SUPPORTED_LANGUAGES } from "../../utils/constants";
import { updateLanguage } from "../../api/mailerfind/users";
import { auth } from "../../lib/firebase/firebase";

type SupportedLanguage = keyof typeof SUPPORTED_LANGUAGES;

function AccountDetails({ user, setUser }: any) {
  const { t } = useTranslation("MyAccount.index");

  const [loading, setLoading] = useState(false);
  const [newUser, setNewUser] = useState(user);
  const [userHasChanged, setUserHasChanged] = useState(false);

  const updateFirebaseUser = async (data: any) => {
    try {
      if (!user) return;
      await setUser({
        firstname: data.firstname,
        surname: data.surname,
      });
      toast.success(t("updated_success"));
      setUserHasChanged(false);
    } catch (error) {
      console.log(error);
      toast.error(t("update_error"));
    }
  };

  useEffect(() => {
    if (!user) return;
    setNewUser(user);
  }, [user]);

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setNewUser({ ...newUser, [name]: value });
    setUserHasChanged(true);
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setLoading(true);
    await updateFirebaseUser(newUser);
    setLoading(false);
  };

  return (
    <div className="card mb-5">
      <div className="card-header">
        <h2 className="card-title h4">{t("basic_info")}</h2>
      </div>
      <div className="card-body">
        <form onSubmit={handleSubmit}>
          <div className="row mb-4">
            <label htmlFor="firstNameLabel" className="col-sm-3 col-form-label form-label">
              {t("full_name")}{" "}
              <i
                className="bi-question-circle text-body ms-1"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                aria-label={t("full_name_aria")}
              />
            </label>
            <div className="col-sm-9">
              <div className="input-group input-group-sm-vertical">
                <input
                  type="text"
                  className="form-control"
                  name="firstname"
                  id="firstNameLabel"
                  placeholder={t("first_name_placeholder")}
                  aria-label={t("first_name_aria")}
                  disabled={!user || loading}
                  value={newUser?.firstname}
                  onChange={handleChange}
                />
                <input
                  type="text"
                  className="form-control"
                  name="surname"
                  id="lastNameLabel"
                  placeholder={t("last_name_placeholder")}
                  aria-label={t("last_name_aria")}
                  onChange={handleChange}
                  value={newUser?.surname}
                  disabled={!user || loading}
                />
              </div>
            </div>
          </div>
          <div className="row mb-4">
            <label htmlFor="emailLabel" className="col-sm-3 col-form-label form-label">
              {t("email")}
            </label>
            <div className="col-sm-9">
              <input
                type="email"
                className="form-control"
                name="email"
                id="emailLabel"
                placeholder={t("email_placeholder")}
                aria-label={t("email_aria")}
                defaultValue={user?.email}
                disabled={true}
              />
            </div>
          </div>
          <div className="d-flex justify-content-end">
            <button type="submit" className="btn btn-primary" disabled={!user || loading || !userHasChanged}>
              {t("save_changes")}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

function Preferences({ user, setUser }: any) {
  const { t, i18n } = useTranslation("MyAccount.index");

  const [loading, setLoading] = useState(false);
  const [formHasChanged, setFormHasChanged] = useState(false);
  const [currency, setCurrency] = useState(user?.preferences?.currency || "usd");
  const [language, setLanguage] = useState<SupportedLanguage>(user?.preferences?.language || DEFAULT_LANGUAGE);
  const [earlyRelease, setEarlyRelease] = useState(user?.preferences?.earlyRelease || false);
  const [communications, setCommunications] = useState(user?.preferences?.communications || false);

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setLoading(true);
    await setUser({
      preferences: {
        currency,
        earlyRelease,
        communications,
      },
    });
    const jwt = await auth.currentUser?.getIdToken();
    if (jwt) await updateLanguage(language, jwt);
    i18n.changeLanguage(language);
    toast.success(t("preferences_updated"));
    setFormHasChanged(false);
    setLoading(false);
  };

  const handleLanguageChange = (lang: SupportedLanguage) => {
    setFormHasChanged(true);
    setLanguage(lang);
  };

  const handleCommunicationsChange = () => {
    setFormHasChanged(true);
    setCommunications(!communications);
  };

  const handleEarlyReleaseChange = () => {
    setFormHasChanged(true);
    setEarlyRelease(!earlyRelease);
  };

  return (
    <div id="preferencesSection" className="card mb-5">
      <div className="card-header">
        <h4 className="card-title">{t("preferences")}</h4>
      </div>
      <div className="card-body">
        <form onSubmit={handleSubmit}>
          <div className="row mb-4">
            <label htmlFor="languageLabel-ts-control" className="col-sm-3 col-form-label form-label" id="languageLabel-ts-label">
              {t("language")}
            </label>
            <div className="col-sm-9">
              <div className="dropdown">
                <button
                  className="btn btn-outline-light text-dark border dropdown-toggle w-100"
                  type="button"
                  id="languageDropdown"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <>
                    <Flag code={SUPPORTED_LANGUAGES[language].flag} className="mr-2 avatar avatar-xs" />{" "}
                    {SUPPORTED_LANGUAGES[language].nativeName}
                  </>
                </button>
                <ul className="dropdown-menu w-100 px-2" aria-labelledby="languageDropdown">
                  {Object.keys(SUPPORTED_LANGUAGES).map((lng) => {
                    const lang = lng as SupportedLanguage;
                    return (
                      <li key={lang}>
                        <div className="dropdown-item" onClick={() => handleLanguageChange(lang)}>
                          <Flag code={SUPPORTED_LANGUAGES[lang].flag} className="mr-2 avatar avatar-xs" />
                          <span className="align-middle">{SUPPORTED_LANGUAGES[lang].nativeName}</span>
                        </div>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          </div>
          <div className="row mb-4">
            <label htmlFor="currencyLabel-ts-control" className="col-sm-3 col-form-label form-label" id="currencyLabel-ts-label">
              {t("currency")}
            </label>
            <div className="col-sm-9">
              <div className="dropdown">
                <button
                  className="btn btn-outline-light text-dark border dropdown-toggle w-100"
                  type="button"
                  id="dropdownMenuButton1"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  {currency === "eur" ? t("eur") : t("usd")}
                </button>
                <ul className="dropdown-menu w-100" aria-labelledby="dropdownMenuButton1">
                  <li>
                    <a className="dropdown-item" href="#" onClick={() => setCurrency("usd")}>
                      {t("usd")}
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="#" onClick={() => setCurrency("eur")}>
                      {t("eur")}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="row mb-4">
            <label htmlFor="timeZoneLabel" className="col-sm-3 col-form-label form-label">
              {t("time_zone")}
            </label>
            <div className="col-sm-9">
              <input
                type="text"
                className="form-control"
                name="currentPassword"
                id="timeZoneLabel"
                placeholder={t("time_zone_placeholder")}
                aria-label={t("time_zone_aria")}
                defaultValue="GMT+01:00"
                readOnly
              />
            </div>
          </div>
          <label className="row mb-4" htmlFor="accounrSettingsPreferencesSwitch1">
            <span className="col-8 col-sm-9 ms-0">
              <span className="d-block text-dark">{t("early_release")}</span>
              <span className="d-block fs-5">{t("early_release_description")}</span>
            </span>
            <span className="col-4 col-sm-3 text-right">
              <label className="switch my-auto">
                <input type="checkbox" onChange={handleEarlyReleaseChange} checked={earlyRelease} />
                <span className="slider round" />
              </label>
            </span>
          </label>
          <label className="row mb-4" htmlFor="accounrSettingsPreferencesSwitch2">
            <span className="col-8 col-sm-9 ms-0">
              <span className="d-block text-dark mb-1">{t("communications")}</span>
              <span className="d-block fs-5">{t("communications_description")}</span>
            </span>
            <span className="col-4 col-sm-3 text-right">
              <label className="switch my-auto">
                <input type="checkbox" onChange={handleCommunicationsChange} checked={communications} />
                <span className="slider round" />
              </label>
            </span>
          </label>{" "}
          <div className="d-flex justify-content-end">
            <button type="submit" className="btn btn-primary" disabled={loading || !formHasChanged}>
              {t("save_changes")}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

function DebugDetails({ user, level = 0 }: any) {
  delete user.proactiveRefresh;
  delete user.reloadUserInfo;
  delete user.auth;

  const MAX_DEPTH = 5;

  const isObject = (value: any) => {
    return value && typeof value === "object" && !Array.isArray(value);
  };

  return (
    <div id="debugSection" className={`card mb-5 ${level > 0 ? "sub-object" : ""}`}>
      {level === 0 && (
        <div className="card-header">
          <h4 className="card-title">Debug</h4>
        </div>
      )}

      <div className="card-body">
        {Object.entries(user).map(([key, value]: any) => (
          <div className="row mb-4" key={key}>
            <label htmlFor="timeZoneLabel" className="col-sm-3 col-form-label form-label">
              {key}
            </label>
            <div className={`col-sm-9`}>
              {isObject(value) && level < MAX_DEPTH ? (
                <DebugDetails user={value} level={level + 1} />
              ) : (
                <input
                  type="text"
                  className="form-control"
                  name={key}
                  id={key}
                  placeholder={key}
                  aria-label={key}
                  defaultValue={value}
                  readOnly
                />
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

function MyAccount() {
  const { user, setUser } = useContext(UserContext) as any;

  return (
    <div className="row justify-content-lg-center">
      <div className="col-lg-9 mx-auto">
        <div className="d-grid gap-3 gap-lg-5">
          <AccountDetails user={user.firebaseUser} setUser={setUser} />
          <Preferences user={user.firebaseUser} setUser={setUser} />
          <Billing user={user.firebaseUser} />
          <OrderHistory />
          {process.env.NODE_ENV === "development" && <DebugDetails user={user.firebaseUser} />}
        </div>
      </div>
    </div>
  );
}

export default MyAccount;
