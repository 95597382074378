import { collection, doc, getDoc, getDocs, onSnapshot, orderBy, query, limit, where } from "firebase/firestore";
import { db } from "./firebase";

/**
 * Get a specific validation by ID
 * @param userId - The user ID
 * @param validationId - The validation ID
 * @returns The validation data or null if not found
 */
export const getValidation = async (userId: string, validationId: string) => {
  const validationRef = doc(db, "users", userId, "validations", validationId);
  const docSnap = await getDoc(validationRef);

  if (!docSnap.exists()) return null;

  const data = docSnap.data();
  return {
    id: docSnap.id,
    ...data,
  };
};

/**
 * Get the most recent validation for a user
 * @param userId - The user ID
 * @returns The most recent validation or null if none found
 */
export const getLatestValidation = async (userId: string) => {
  const validationsRef = collection(db, "users", userId, "validations");
  const q = query(validationsRef, orderBy("createdAt", "desc"), limit(1));
  const querySnapshot = await getDocs(q);

  if (querySnapshot.empty) return null;

  const validationDoc = querySnapshot.docs[0];
  return {
    id: validationDoc.id,
    ...validationDoc.data(),
  };
};

/**
 * Get validations for a specific analysis
 * @param userId - The user ID
 * @param analysisId - The analysis ID
 * @returns Array of validations for the specified analysis
 */
export const getValidationsByAnalysis = async (userId: string, analysisId: string): Promise<any[]> => {
  const validationsRef = collection(db, "users", userId, "validations");
  const analysisRef = doc(db, "users", userId, "analysis", analysisId);
  const q = query(validationsRef, where("analysis", "==", analysisRef), orderBy("createdAt", "desc"));

  const querySnapshot = await getDocs(q);
  const validations: any[] = [];

  querySnapshot.forEach((doc) => {
    validations.push({
      id: doc.id,
      ...doc.data(),
    });
  });

  return validations;
};

/**
 * Listen to a specific validation in real-time
 * @param userId - The user ID
 * @param validationId - The validation ID
 * @param onUpdate - Callback function when the validation updates
 * @param onError - Callback function when an error occurs
 * @returns An unsubscribe function to stop listening
 */
export const listenValidation = (
  userId: string,
  validationId: string,
  onUpdate: (validation: any | null) => void,
  onError: (error: Error) => void
) => {
  const validationRef = doc(db, "users", userId, "validations", validationId);

  const unsubscribe = onSnapshot(
    validationRef,
    (doc) => {
      if (doc.exists()) {
        onUpdate({
          id: doc.id,
          ...doc.data(),
        });
      } else {
        onUpdate(null);
      }
    },
    onError
  );

  return unsubscribe;
};

/**
 * Listen to the most recent validation in real-time
 * @param userId - The user ID
 * @param onUpdate - Callback function when the validation updates
 * @param onError - Callback function when an error occurs
 * @returns An unsubscribe function to stop listening
 */
export const listenLatestValidation = (userId: string, onUpdate: (validation: any | null) => void, onError: (error: Error) => void) => {
  const validationsRef = collection(db, "users", userId, "validations");
  const q = query(validationsRef, orderBy("createdAt", "desc"), limit(1));

  const unsubscribe = onSnapshot(
    q,
    (querySnapshot) => {
      if (!querySnapshot.empty) {
        const doc = querySnapshot.docs[0];
        onUpdate({
          id: doc.id,
          ...doc.data(),
        });
      } else {
        onUpdate(null);
      }
    },
    onError
  );

  return unsubscribe;
};
