import React, { useContext, useEffect } from "react";
import { UserContext } from "../contexts/UserContext";
import moment from "moment";
import { Calendar, Journals, X } from "react-bootstrap-icons";
import { useTranslation } from "react-i18next";

function FreeConsultation() {
  const { user } = useContext(UserContext) as any;
  const { t, i18n } = useTranslation("Component.FreeConsultation");

  const availableInLanguages = ["es", "en"];

  const startTime = user?.firebaseUser?.freeConsultation?.startTime;
  const humanReadableDate = startTime
    ? `${t("scheduledFor")} ${moment(startTime).calendar(null, {
        sameDay: `[${t("todayAt")}] LT`,
        nextDay: `[${t("tomorrowAt")}] LT`,
        nextWeek: `dddd [${t("at")}] LT`,
        lastDay: `[${t("yesterdayAt")}] LT`,
        lastWeek: `[${t("last")} dddd ${t("at")}] LT`,
        sameElse: `DD/MM/YYYY [${t("at")}] LT`,
      })}`
    : t("noMeetingScheduled");

  // Do not render the component if the language is not available
  if (!availableInLanguages.includes(i18n.language)) return null;

  return (
    <div className="card h-100">
      <div className="card-body">
        <div className="d-flex">
          <img
            src="/assets/img/nico.jpg"
            className="img-fluid avatar avatar-centered avatar-xl avatar-circle mr-3 border-primary"
            alt="Nico"
          />
          <div>
            <h5 className="card-title">{t("cardTitle")}</h5>
            <p>{t("cardDescription")}</p>
          </div>
        </div>
        <div>
          <a className="btn btn-sm btn-outline-primary w-100" href={t("bookADemoLink")} target="_blank" rel="noreferrer">
            <Calendar className="mr-1" /> <span className="align-middle">{t("bookADemo")}</span>
          </a>
        </div>
      </div>
    </div>
  );
}

export default FreeConsultation;
