import React, { useEffect, useState } from "react";
import TimezoneSelect, { ITimezone, allTimezones, ITimezoneOption } from "react-timezone-select";
import { DaysKeys, Email, ScheduleDays } from "../../types/EmailTypes";
import Skeleton from "react-loading-skeleton";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";

interface SchedulingProps {
  email: Email;
  setEmail: (value: any) => void;
  setSaved: (value: any) => void;
  loading?: boolean;
}

const customStyles = {
  control: (base: any, state: any) => ({
    ...base,
    borderColor: state.isFocused ? "#e7eaf3" : "#e7eaf3",
    boxShadow: state.isFocused ? null : null,
    "&:hover": {
      borderColor: state.isFocused ? "#e7eaf3" : "#e7eaf3",
    },
  }),
};

function Scheduling({ email, setEmail, setSaved, loading }: SchedulingProps) {
  const { t } = useTranslation("NewEmail.Scheduling");

  const DAYS: Array<{ key: DaysKeys; label: string }> = [
    { key: "mon", label: t("monday") },
    { key: "tue", label: t("tuesday") },
    { key: "wed", label: t("wednesday") },
    { key: "thu", label: t("thursday") },
    { key: "fri", label: t("friday") },
    { key: "sat", label: t("saturday") },
    { key: "sun", label: t("sunday") },
  ];

  // Estado local del componente: puede ser null mientras esperamos los datos
  const [selectedTimezone, setSelectedTimezone] = useState<ITimezone>(email.timeZone || Intl.DateTimeFormat().resolvedOptions().timeZone);

  const handleTimezoneChange = (value: ITimezoneOption) => {
    setSelectedTimezone(value);
    setEmail({ ...email, timeZone: value.value });
    setSaved(false);
  };

  // 2. Controlamos scheduleDays
  //    Si no existe, definimos un valor por defecto (lunes-viernes = true, finde = false)
  const defaultDays: ScheduleDays = {
    mon: true,
    tue: true,
    wed: true,
    thu: true,
    fri: true,
    sat: false,
    sun: false,
  };

  const [scheduleDays, setScheduleDays] = useState<ScheduleDays>(email.scheduleDays || defaultDays);

  // 3. Al cambiar un día, nos aseguramos de que nunca se queden TODOS en false
  const handleDayToggle = (dayKey: string) => {
    const newValue = !scheduleDays[dayKey as DaysKeys];

    // Comprobamos si se quedaría todo a false
    const daysEnabledCount = Object.keys(scheduleDays).reduce((acc, key) => {
      // TS se queja, pero si transformas `key` a `key as DaysKeys`,
      // ya sabe que es una de "mon"|"tue"|"wed"|"thu"|"fri"|"sat"|"sun"
      if (key === dayKey) {
        return acc + (newValue ? 1 : 0);
      }
      return acc + (scheduleDays[key as DaysKeys] ? 1 : 0);
    }, 0);

    if (daysEnabledCount === 0) {
      // No podemos dejar todo desmarcado, abortamos
      toast.error(t("noDaysEnabledErrorMessage"));
      return;
    }

    setEmail({ ...email, scheduleDays: { ...scheduleDays, [dayKey]: newValue } });
    setSaved(false);

    setScheduleDays({
      ...scheduleDays,
      [dayKey]: newValue,
    });
  };

  return (
    <div className="row">
      <div className="col-lg-8">
        <div className="card">
          <div className="card-header">
            <h5 className="card-title">{t("scheduleDays")}</h5>
          </div>
          <div className="card-body">
            <p>{t("scheduleDaysDescription")}</p>
            <div className="">
              {DAYS.map((day) => (
                <div key={day.key} className="day-item col-auto mb-2">
                  <label className="switch my-auto mr-2">
                    <input type="checkbox" onChange={() => handleDayToggle(day.key)} checked={scheduleDays[day.key] || false} />
                    <span className="slider round" />
                  </label>
                  <span className="m-0 p-0">{day.label}</span>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="col-lg-4">
        <div className="card mb-4">
          <div className="card-header">
            <h5 className="card-title">{t("timeZone")}</h5>
          </div>
          <div className="card-body">
            <p>{t("timeZoneDescription")}</p>
            <div className="select-wrapper">
              {loading ? (
                <Skeleton height={35} />
              ) : (
                <TimezoneSelect
                  value={selectedTimezone}
                  onChange={handleTimezoneChange}
                  styles={customStyles}
                  timezones={{
                    ...allTimezones,
                    "America/Lima": "Pittsburgh",
                    "Europe/Berlin": "Frankfurt",
                  }}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Scheduling;
