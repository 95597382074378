import { useState } from "react";
import { auth } from "../lib/firebase/firebase";
import { validateAnalysis } from "../api/mailerfind/analysis";
import { getIntegrationByTypeAndProject } from "../lib/firebase/integrations";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";

/**
 * Hook for handling email validation functionality
 * @param userId - The user ID
 * @param projectId - The project ID
 * @param namespace - The translation namespace (e.g., 'Emails.index', 'ViewEmail.index')
 * @returns Object containing validation functions and state
 */
const useEmailValidation = (userId: string, projectId: string | null, namespace: string) => {
  const [redirectToValidation, setRedirectToValidation] = useState<string | null>(null);
  const [showIntegrationModal, setShowIntegrationModal] = useState<boolean>(false);
  const [selectedIntegration, setSelectedIntegration] = useState<string | null>(null);
  const { t } = useTranslation(namespace);

  /**
   * Starts the email validation process
   * @param analysisId - The analysis ID to validate
   * @param integration - The integration type to use for validation
   * @returns Promise that resolves when validation is complete
   */
  const startEmailValidation = async (analysisId: string, integration: string) => {
    if (!userId || !analysisId) {
      return;
    }

    // Check if the integration exists
    const projectIntegration = await getIntegrationByTypeAndProject(userId, integration, projectId || null);

    if (!projectIntegration) {
      // If integration doesn't exist, show integration modal instead of error toast
      setSelectedIntegration(integration);
      setShowIntegrationModal(true);
      return;
    }

    // If integration exists, call the validateAnalysis endpoint
    try {
      const jwt = await auth.currentUser!.getIdToken();
      const response = await validateAnalysis(analysisId, integration, jwt);

      // Show success message
      toast.success(t("emailValidationStarted"), {
        duration: 5000, // Show the message for a longer time
      });

      // After successful validation, set redirect path
      setRedirectToValidation(`/analysis/${analysisId}/validations/${response.validation.id}`);
      return response;
    } catch (err: any) {
      toast.error(err.message || t("emailValidationError"));
      throw err;
    }
  };

  /**
   * Clears the redirect state
   */
  const clearRedirect = () => {
    setRedirectToValidation(null);
  };

  /**
   * Closes the integration modal
   */
  const closeIntegrationModal = () => {
    setShowIntegrationModal(false);
  };

  return {
    redirectToValidation,
    startEmailValidation,
    clearRedirect,
    showIntegrationModal,
    setShowIntegrationModal,
    selectedIntegration,
    closeIntegrationModal,
  };
};

export default useEmailValidation;
