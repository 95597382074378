import React from "react";
import { useTranslation } from "react-i18next";

function BeforeYouDiveIn() {
  const { t, i18n } = useTranslation("Component.BeforeYouDiveIn");

  if (i18n.language !== "en" && i18n.language !== "es" && i18n.language !== "it") {
    return null;
  }

  return (
    <div className="card">
      <div className="card-header">
        <h4 className="card-header-title">{t("welcomeTitle")}</h4>
      </div>
      <div className="card-body p-0">
        {i18n.language === "en" && (
          <div style={{ position: "relative", paddingBottom: "64.70940683043739%", height: 0 }}>
            <iframe
              src="https://www.loom.com/embed/35f02a9094d4426aab1cb22cf4207e13?sid=31542cdf-844b-4ca6-928f-3fdb4935a26b"
              frameBorder={0}
              allowFullScreen
              title="Welcome to Mailerfind"
              style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}
            />
          </div>
        )}

        {i18n.language === "es" && (
          <div style={{ position: "relative", paddingBottom: "64.92335437330928%", height: 0 }}>
            <iframe
              src="https://www.loom.com/embed/eaf2f3a1fe1f4ddcb1876fb5e3137102?sid=0f57bafd-f47e-4ed9-afd2-7867d4b9801d"
              frameBorder={0}
              title="Bienvenido a Mailerfind"
              allowFullScreen
              style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}
            />
          </div>
        )}

        {i18n.language === "it" && (
          <div style={{ position: "relative", paddingBottom: "64.67065868263472%", height: 0 }}>
            <iframe
              src="https://www.loom.com/embed/2cd8f3b7e44f4535a8d3805700004744?sid=c3a4bbf5-8296-41d4-9dd6-c3d11521dd27"
              frameBorder={0}
              allowFullScreen
              title="Benvenuto su Mailerfind"
              style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default BeforeYouDiveIn;
