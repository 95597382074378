import { useContext, useEffect, useState } from "react";
import Header from "../../components/Header";
import { useParams, Link, useNavigate, Navigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { auth } from "../../lib/firebase/firebase";
import Loading from "../../components/Loading";
import { CheckCircle, EnvelopeCheck, ExclamationTriangleFill, PauseCircle, PencilSquare, PlayCircle, Trash } from "react-bootstrap-icons";
import { listenAnalysis } from "../../lib/firebase/analysis";
import { listenEmail } from "../../lib/firebase/emails";
import toast from "react-hot-toast";
import { Email } from "../../types/EmailTypes";
import Analytics from "./Analytics";
import Leads from "./Leads";
import { pauseCampaign, startCampaign } from "../../api/mailerfind/email";
import ConfirmModal from "../../components/ConfirmModal";
import { Button, ButtonGroup, Dropdown, DropdownButton, DropdownDivider } from "react-bootstrap";
import PremiumModal from "../../components/PremiumModal";
import { UserContext } from "../../contexts/UserContext";
import EmailVerificationModal from "../../components/EmailVerificationModal";
import IntegrationInfoModal from "../../components/IntegrationInfoModal";
import { ProjectContext } from "../../contexts/ProjectContext";
import useEmailValidation from "../../hooks/useEmailValidation";

function ViewEmail() {
  const { t } = useTranslation("ViewEmail.index");
  const navigate = useNavigate();
  const { id } = useParams() as any;
  const [loading, setLoading] = useState(true);
  const { user } = useContext(UserContext) as any;
  const { project } = useContext(ProjectContext) as any;

  // Datos relacionados con el email
  const [analysis, setAnalysis] = useState<any>(null);
  const [email, setEmail] = useState<Email | null>(null);
  const [totalUsers, setTotalUsers] = useState(0);
  const [confirmIsLoading, setConfirmIsLoading] = useState(false);
  const [showPremiumModal, setShowPremiumModal] = useState(false);
  const [showEmailVerificationModal, setShowEmailVerificationModal] = useState(false);
  const { 
    redirectToValidation, 
    startEmailValidation,
    showIntegrationModal,
    setShowIntegrationModal,
    selectedIntegration 
  } = useEmailValidation(
    user?.firebaseUser?.uid || "",
    project?.id || null,
    "ViewEmail.index"
  );

  const [tab, setTab] = useState("analytics");

  // Estado para el modal de confirmación
  const [confirmModal, setConfirmModal] = useState<{ action: "start" | "pause" | null; show: boolean }>({
    action: null,
    show: false,
  });

  const calculateTotalUsers = (email: any, followUpEmail: any, analysis: any) => {
    if (email?.followUp?.isAFollowUpEmail === true) {
      switch (email?.followUp?.followUpMode) {
        case "open":
          return analysis.emailsFoundCount - followUpEmail.opens;
        case "click":
          return analysis.emailsFoundCount - (followUpEmail.uniqueClicks || followUpEmail.clicks);
        default:
          toast.error(t("errors.followUpModeNotRecognized"));
          return analysis.emailsFoundCount;
      }
    } else {
      return analysis.emailsFoundCount;
    }
  };

  useEffect(() => {
    if (!id) return setLoading(false);
    const unsubscribe = listenEmail(
      user.firebaseUser.uid,
      id,
      (emailData) => setEmail(emailData),
      (error) => toast.error(error.message)
    );
    return () => unsubscribe();
  }, [id, user.firebaseUser.uid]);

  useEffect(() => {
    if (!id || !email) return;
    const unsubscribe = listenAnalysis(
      user.firebaseUser.uid,
      email.analysis,
      (analysisData) => {
        setAnalysis(analysisData);
        setLoading(false);
        const total = calculateTotalUsers(email, null, analysisData);
        setTotalUsers(total);
      },
      (error) => toast.error(error.message)
    );
    return () => unsubscribe();
  }, [id, email, user.firebaseUser.uid]);

  const handleStartCampaign = async (campaignId: string) => {
    try {
      const jwt = await auth.currentUser!.getIdToken();
      await startCampaign(campaignId, jwt);
    } catch (err: any) {
      toast.error(err.message);
    }
  };

  const handlePauseCampaign = async (campaignId: string) => {
    try {
      const jwt = await auth.currentUser!.getIdToken();
      await pauseCampaign(campaignId, jwt);
    } catch (err: any) {
      toast.error(err.message);
    }
  };

  const handleConfirmAction = async () => {
    if (!id || !confirmModal.action) return;
    if (user.firebaseUser.plan === "free") {
      setConfirmModal({ action: null, show: false });
      setShowPremiumModal(true);
      return;
    }
    setConfirmIsLoading(true);
    if (confirmModal.action === "start") {
      await handleStartCampaign(id);
    } else if (confirmModal.action === "pause") {
      await handlePauseCampaign(id);
    }
    setConfirmIsLoading(false);
    setConfirmModal({ action: null, show: false });
  };

  const handleVerify = async (integrationType: any) => {
    // Use the hook's startEmailValidation function which handles integration check
    try {
      await startEmailValidation(email?.analysis || "", integrationType);
    } catch (err: any) {
      // Error handling is done in the hook
      throw err;
    }
  };

  if (loading) return <Loading />;
  if (!email || !analysis)
    return (
      <div className="row justify-content-center align-items-sm-center py-sm-10">
        <div className="col-9 col-sm-6 col-lg-4">
          <div className="text-center text-sm-end me-sm-4 mb-5 mb-sm-0">
            <img src="/assets/svg/illustrations/oc-thinking.svg" className="img-fluid" alt={t("thinkingAlt")} />
          </div>
        </div>
        <div className="col-sm-6 col-lg-4 text-center text-sm-start">
          <h1 className="display-1 mb-0">404</h1>
          <p className="lead">
            {t("oops")}, {!email ? t("errors.email") : !analysis ? t("errors.analysis") : t("errors.sender")} {t("notFound")}
          </p>
          <Link className="btn btn-primary" to="/emails">
            {t("goBack")}
          </Link>
        </div>
      </div>
    );

  // If we have a redirect path, navigate to it
  if (redirectToValidation) {
    return <Navigate to={redirectToValidation} />;
  }

  return (
    <>
      <Header
        title={t("viewEmailStats")}
        subtitle={email.title}
        breadCrumbItems={[{ title: t("emails"), href: "/emails" }, { title: email.title }]}
      >
        <ButtonGroup>
          {["NOT_STARTED", "PAUSED", "ERROR"].includes(email.status) && (
            <button
              className="text-right btn btn-primary"
              disabled={loading}
              onClick={() => setConfirmModal({ action: "start", show: true })}
            >
              <PlayCircle className="mr-1" />
              <span className="align-middle">{t("startSending") || "Start sending"}</span>
            </button>
          )}
          {email.status === "RUNNING" && (
            <button
              className="text-right btn btn-primary"
              disabled={loading}
              onClick={() => setConfirmModal({ action: "pause", show: true })}
            >
              <PauseCircle className="mr-1" />
              <span className="align-middle">{t("pauseSending") || "Pause sending"}</span>
            </button>
          )}

          {["STARTING", "PAUSING"].includes(email.status) && (
            <button className="btn btn-primary px-10" disabled>
              <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
            </button>
          )}

          {["COMPLETED"].includes(email.status) && (
            <button className="text-right btn btn-primary" disabled>
              <PlayCircle className="mr-1" />
              <span className="align-middle">{t("startSending") || "Start sending"}</span>
            </button>
          )}

          <DropdownButton as={ButtonGroup} title="" id="bg-nested-dropdown">
            <Dropdown.Item eventKey="2" onClick={() => navigate(`/emails/${email.id}/edit`)} className="px-3">
              <PencilSquare className="mr-1" /> <span className="px-0 align-middle">{t("edit")}</span>
            </Dropdown.Item>

            <Dropdown.Item eventKey="3" onClick={() => setShowEmailVerificationModal(true)} className="px-3">
              <EnvelopeCheck className="mr-1" /> <span className="px-0 align-middle">{t("seeEmailValidationResults")}</span>
            </Dropdown.Item>

            <DropdownDivider />
            <Dropdown.Item eventKey="4" className="px-3 text-danger">
              <Trash className="mr-1" /> <span className="px-0 align-middle">{t("delete")}</span>
            </Dropdown.Item>
          </DropdownButton>
        </ButtonGroup>
      </Header>

      <ul className="nav nav-tabs mb-4">
        <li className="nav-item">
          <div
            className={`nav-link ${tab === "analytics" ? "active" : ""}`}
            onClick={() => setTab("analytics")}
            style={{ cursor: "pointer" }}
          >
            {t("analytics") || "Analytics"}
          </div>
        </li>
        <li className="nav-item">
          <div className={`nav-link ${tab === "leads" ? "active" : ""}`} onClick={() => setTab("leads")} style={{ cursor: "pointer" }}>
            {t("leads") || "Leads"} <span className="badge bg-soft-dark text-dark ms-1">{totalUsers}</span>
          </div>
        </li>
      </ul>

      {email.status === "ERROR" && email.errorMessage && (
        <div className="alert alert-soft-danger" role="alert">
          <ExclamationTriangleFill /> {email.errorMessage}
        </div>
      )}

      {tab === "analytics" && <Analytics />}
      {tab === "leads" && <Leads />}

      <ConfirmModal
        show={confirmModal.show}
        setShow={(show: boolean) => setConfirmModal({ ...confirmModal, show })}
        onConfirm={handleConfirmAction}
        loading={confirmIsLoading}
        text={confirmModal.action === "start" ? t("confirmStartSending") : confirmModal.action === "pause" ? t("confirmPauseSending") : ""}
      />

      <PremiumModal show={showPremiumModal} setShow={setShowPremiumModal} />

      <EmailVerificationModal
        show={showEmailVerificationModal}
        close={() => setShowEmailVerificationModal(false)}
        onVerify={handleVerify}
        analysisId={email?.analysis || ""}
        userId={user?.firebaseUser?.uid || ""}
      />

      <IntegrationInfoModal show={showIntegrationModal} setShow={setShowIntegrationModal} integrationType={selectedIntegration} />
    </>
  );
}

export default ViewEmail;
