import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { Link45deg } from "react-bootstrap-icons";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { integrations } from "../utils/constants";
import { capitalizeString } from "../utils/helpers";

function IntegrationInfoModal({ show, setShow, integrationType }: any) {
  const [integration, setIntegration] = useState<any>(null);
  const [helpLink, setHelpLink] = useState<string | undefined>(undefined);
  const { t, i18n } = useTranslation("Integrations");
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const handleClose = () => setShow(false);

  const handleConnect = () => {
    handleClose();
    navigate(`/integrations#${integrationType}`);
  };

  useEffect(() => {
    if (!integrationType) return;
    const integration = integrations.find((integration) => integration.slug === integrationType);
    setIntegration(integration);
  }, [integrationType]);

  useEffect(() => {
    if (!integration) return;
    const helpLink = getHelpLink();
    setHelpLink(helpLink);
  }, [integration]);

  // Función para obtener el enlace correcto según el idioma
  const getHelpLink = (): string | undefined => {
    if (!integration.helpCenterLink) return undefined;
    const currentLang = i18n.language;
    if (integration.helpCenterLink[currentLang]) {
      return integration.helpCenterLink[currentLang];
    } else if (integration.helpCenterLink["en"]) {
      return integration.helpCenterLink["en"];
    } else {
      const locales = Object.keys(integration.helpCenterLink);
      return locales.length > 0 ? integration.helpCenterLink[locales[0]] : undefined;
    }
  };

  if (!integration) return null;

  return (
    <>
      <Modal show={show} onHide={handleClose} centered size="lg">
        <Modal.Body className="p-0 rounded">
          {integration.connectionMethod === "manual" ? (
            <iframe
              src={helpLink}
              title={integration.name}
              frameBorder={0}
              className="w-100 rounded border-none"
              style={{ minHeight: "500px" }}
            />
          ) : (
            <div className="row me-0">
              <div className="col-lg-6 col-0 d-none d-lg-block p-0">
                <img
                  src={integration?.banner}
                  className="img-fluid"
                  alt="Smartlead integration"
                  style={{
                    borderTopLeftRadius: "0.75rem",
                    borderBottomLeftRadius: "0.75rem",
                    height: "100%",
                    objectFit: "cover",
                  }}
                />
              </div>
              <div className="col-lg-6 col-12 px-6 py-6 my-auto">
                <Title integrationName={capitalizeString(integration.slug)} />
                <p>{t(integration?.descriptionKey)}</p>
                <button className="btn btn-primary w-100 mt-2" disabled={loading} onClick={handleConnect}>
                  <Link45deg className="mr-1 align-middle" />
                  <span className="align-middle">Conectar</span>
                </button>
              </div>
            </div>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
}

function Title({ integrationName }: any) {
  const { t } = useTranslation("Component.IntegrationInfoModal");
  return <h2>{t("title", { integrationName })}</h2>;
}

export default IntegrationInfoModal;
