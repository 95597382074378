import React, { useCallback, useState } from "react";
import toast from "react-hot-toast";
import { auth } from "../../lib/firebase/firebase";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { BoxArrowUpRight, EyeFill, EyeSlashFill, QuestionCircleFill } from "react-bootstrap-icons";
import RichTextEditor from "../../components/RichTextEditor";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { testSender } from "../../api/mailerfind/sender";

function Form({
  id,
  updateSender,
  sender,
  setEmailAddress,
  errors = {}, // Asignamos un valor por defecto vacío
  loading,
  handleSubmit,
  saved,
  setSaved,
  handleCancel,
}: any) {
  const [testIsLoading, setTestIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const { t } = useTranslation("Sender.Form");

  const testSMTPConnection = async () => {
    if (!id) {
      toast.error(t("saveSettingsFirst"));
      return;
    }
    setTestIsLoading(true);
    const jwt = (await auth.currentUser?.getIdToken()) as string;
    await updateSender(sender.senderId || sender.id);
    try {
      const response = await testSender(sender.senderId || sender.id, jwt);
      toast.success(response.message);
    } catch (error: any) {
      toast.error(error.message);
    } finally {
      setTestIsLoading(false);
    }
  };

  const handleChange = useCallback(
    (e: any) => {
      let { name, value } = e.target;
      setSaved(false);

      if (name === "smtp.port") {
        value = parseInt(value, 10);
      }
      if (name === "delayBetweenEmails" || name === "messagesPerDay") {
        value = parseInt(value, 10);
      }
      setEmailAddress((prev: any) => {
        const nextState = _.cloneDeep(prev);
        _.set(nextState, name, value);
        return nextState;
      });
    },
    [setEmailAddress, setSaved]
  );

  const handleSignatureChange = useCallback(
    (value: any) => {
      setSaved(false);
      setEmailAddress((prev: any) => ({
        ...prev,
        signature: value,
      }));
    },
    [setEmailAddress, setSaved]
  );

  return (
    <div className="card">
      <div className="card-header card-header-content-between">
        <h4 className="card-header-title">{t("settings")}</h4>
      </div>
      <div className="card-body pb-5">
        <div className="row">
          {/* Campo: Nombre del sender */}
          <div className="col-lg-6">
            <div className="form-group">
              <label htmlFor="senderName">{t("senderName")}</label>
              <input
                type="text"
                id="senderName"
                name="name"
                disabled={loading}
                onChange={handleChange}
                value={sender.name}
                placeholder={t("placeholder.senderName")}
                className={"form-control" + (errors["name"] ? " is-invalid" : "")}
              />
            </div>
          </div>
          {/* Campo: Mensajes por día */}
          <div className="col-lg-6">
            <div className="form-group">
              <label htmlFor="messagesPerDay">
                {t("messagesPerDay")}
                <OverlayTrigger
                  delay={{ hide: 450, show: 300 }}
                  overlay={(props) => <Tooltip {...props}>{t("tooltip.messagesPerDay")}</Tooltip>}
                  placement="bottom"
                >
                  <QuestionCircleFill size={14} className="ml-1 mb-1" />
                </OverlayTrigger>
              </label>
              <input
                type="number"
                id="messagesPerDay"
                name="messagesPerDay"
                disabled={loading}
                onChange={handleChange}
                value={sender.messagesPerDay}
                min={10}
                max={1000}
                autoComplete="off"
                placeholder={t("placeholder.messagesPerDay")}
                className={"form-control" + (errors["messagesPerDay"] ? " is-invalid" : "")}
              />
            </div>
          </div>
          {/* Campo: Email */}
          <div className="col-12">
            <div className="form-group">
              <label htmlFor="emailAddress">{t("emailAddress")}</label>
              <input
                type="text"
                id="emailAddress"
                name="email"
                disabled={sender.provider === "google" || loading}
                onChange={handleChange}
                value={sender.email}
                placeholder={t("placeholder.emailAddress")}
                className={"form-control" + (errors["email"] ? " is-invalid" : "")}
              />
            </div>
          </div>
          {/* Campo: API Key (solo para sendgrid) */}
          {sender.provider === "sendgrid" && (
            <div className="col-12">
              <div className="form-group">
                <label htmlFor="apiKey">
                  <a href="https://app.sendgrid.com/settings/api_keys" target="_blank" rel="noreferrer">
                    <span className="align-middle">{t("sendgridAPIKey")}</span> <BoxArrowUpRight size={10} />
                  </a>
                </label>
                <input
                  type="password"
                  id="apiKey"
                  name="apiKey"
                  disabled={loading}
                  onChange={handleChange}
                  value={sender.apiKey}
                  placeholder={t("placeholder.sendgridAPIKey")}
                  className={"form-control" + (errors["apiKey"] ? " is-invalid" : "")}
                />
              </div>
            </div>
          )}
        </div>
        {/* Sección SMTP */}
        {sender.provider === "smtp" && (
          <>
            <label htmlFor="smtp.host">{t("smtpSettings")}</label>
            <div className="row border rounded mx-0 mb-5 p-4">
              <div className="col-8">
                <div className="form-group">
                  <label htmlFor="smtp.host">{t("host")}</label>
                  <input
                    type="text"
                    id="smtp.host"
                    name="smtp.host"
                    autoComplete="off"
                    disabled={loading}
                    onChange={handleChange}
                    value={sender.smtp?.host}
                    placeholder={t("placeholder.host")}
                    className={"form-control" + (errors["smtp.host"] ? " is-invalid" : "")}
                  />
                </div>
              </div>
              <div className="col-4">
                <div className="form-group">
                  <label htmlFor="smtp.port">{t("port")}</label>
                  <select
                    id="smtp.port"
                    name="smtp.port"
                    autoComplete="off"
                    disabled={loading}
                    onChange={handleChange}
                    value={sender?.smtp?.port}
                    className={"form-control" + (errors["smtp.port"] ? " is-invalid" : "")}
                  >
                    <option value={-1} disabled>
                      {t("select")}
                    </option>
                    <option value={25}>{t("portOptions.notSecure")}</option>
                    <option value={465}>{t("portOptions.sslTls")}</option>
                    <option value={587}>{t("portOptions.starttls")}</option>
                  </select>
                </div>
              </div>
              <div className="col-6">
                <div className="form-group">
                  <label htmlFor="smtp.auth.username">{t("username")}</label>
                  <div style={{ display: "flex" }}>
                    <input
                      type="text"
                      id="smtp.auth.username"
                      name="smtp.auth.username"
                      autoComplete="off"
                      disabled={loading}
                      onChange={handleChange}
                      value={sender.smtp?.auth?.username}
                      placeholder={t("placeholder.username")}
                      className={"form-control" + (errors["smtp.auth.username"] ? " is-invalid" : "")}
                    />
                  </div>
                </div>
              </div>
              <div className="col-6">
                <div className="form-group">
                  <label htmlFor="smtp.auth.password">{t("password")}</label>
                  <div style={{ display: "flex" }}>
                    <input
                      type={showPassword ? "text" : "password"}
                      id="smtp.auth.password"
                      name="smtp.auth.password"
                      autoComplete="off"
                      disabled={loading}
                      onChange={handleChange}
                      value={sender.smtp?.auth?.password}
                      placeholder={t("placeholder.password")}
                      className={"form-control" + (errors["smtp.auth.password"] ? " is-invalid" : "")}
                    />
                    <div
                      onClick={() => setShowPassword(!showPassword)}
                      style={{ cursor: "pointer", marginLeft: "-40px", marginTop: "8px" }}
                    >
                      {showPassword ? <EyeSlashFill size={20} /> : <EyeFill size={20} />}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12">
                <button type="button" className="btn btn-primary btn-sm" onClick={testSMTPConnection} disabled={testIsLoading}>
                  {testIsLoading ? (
                    <>
                      <span className="spinner-border spinner-border-sm align-middle mr-2" role="status" aria-hidden="true"></span>
                      <span className="m-0 p-0 align-middle">{t("testing")}...</span>
                    </>
                  ) : (
                    t("testSMTPConnection")
                  )}
                </button>
              </div>
            </div>
          </>
        )}
        {/* Firma */}
        <div className="row">
          <div className="col-12">
            <div className="pb-2">
              <label>{t("signature")}</label>
              <RichTextEditor value={sender.signature} setValue={handleSignatureChange} />
            </div>
          </div>
        </div>
      </div>
      {/* Botones de footer */}
      <div className="card-footer d-flex justify-content-end align-items-center gap-3">
        <button type="button" className="btn btn-white mr-3" onClick={handleCancel}>
          {t("cancel")}
        </button>
        <button className="btn btn-primary mr-2" onClick={handleSubmit} disabled={saved || loading}>
          {t("saveSettings")}
        </button>
      </div>
    </div>
  );
}

export default Form;
