import { useTranslation } from "react-i18next";
import React, { useContext } from "react";
import { UserContext } from "../contexts/UserContext";
import { SUPPORTED_LANGUAGES } from "../utils/constants";

type SupportedLanguage = keyof typeof SUPPORTED_LANGUAGES;

function LanguageSwitcher({ style }: { style?: React.CSSProperties }) {
  const { i18n } = useTranslation();
  const { user, setUser } = useContext(UserContext) as any;

  const handleChangeLanguage = async (language: SupportedLanguage) => {
    i18n.changeLanguage(language);
    if (!user) return;
    await setUser({
      "preferences.language": language,
    });
  };

  const currentLanguage =
    (i18n.resolvedLanguage as SupportedLanguage) in SUPPORTED_LANGUAGES ? (i18n.resolvedLanguage as SupportedLanguage) : "es";

  return (
    <div className="hs-unfold" style={style}>
      <div
        className="js-hs-unfold-invoker hs-mega-menu-invoker"
        id="withMegaMenuDropdownEg"
        role="button"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        <a className="dropdown-item rounded py-3" href="#">
          <img
            className="avatar avatar-xss avatar-circle mr-2"
            src={`/assets/vendor/flag-icon-css/flags/1x1/${currentLanguage}.svg`}
            alt="Flag"
          />
          <span className="text-truncate pr-2 align-middle" title="Language">
            {SUPPORTED_LANGUAGES[currentLanguage].nativeName}
          </span>
        </a>
      </div>
      {/* Dropdown */}
      <div className="dropdown">
        <div className="dropdown-menu py-2 px-3" style={{ minWidth: 250, transform: "translate3d(0px,-20px,0px)" }}>
          {Object.keys(SUPPORTED_LANGUAGES).map((lng) => {
            const lang = lng as SupportedLanguage;
            return (
              <div className="dropdown-item-text p-1" key={lang}>
                <div className="media align-items-center">
                  <div className="dropdown-item p-3" onClick={() => handleChangeLanguage(lang)} style={{ cursor: "pointer" }}>
                    <img
                      className="avatar avatar-xss avatar-circle mr-2"
                      src={`/assets/vendor/flag-icon-css/flags/1x1/${lang}.svg`}
                      alt="Flag"
                    />
                    <span
                      className="text-truncate pr-2"
                      title="Language"
                      style={{ fontWeight: currentLanguage === lang ? "bold" : "normal" }}
                    >
                      {SUPPORTED_LANGUAGES[lang].nativeName}
                    </span>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      {/* End Dropdown */}
    </div>
  );
}

export default LanguageSwitcher;
