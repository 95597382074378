import React, { useState, useEffect, useCallback } from "react";
import { Button, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { integrations } from "../utils/constants";
import { getValidationsByAnalysis } from "../lib/firebase/validations";
import { ArrowLeft, CheckCircle, ExclamationTriangleFill, Eye, PlusCircle } from "react-bootstrap-icons";
import { Link } from "react-router-dom";
import Moment from "react-moment";
import { capitalizeString } from "../utils/helpers";
import toast from "react-hot-toast";

/**
 * Email verification modal component
 *
 * @param {Object} props - Component props
 * @param {boolean} props.show - Whether to show the modal
 * @param {Function} props.close - Function to close the modal
 * @param {Function} props.onVerify - Function to call when the verify button is clicked
 * @param {string} props.title - Title of the modal
 * @param {string} props.analysisId - ID of the analysis to verify
 * @param {string} props.userId - ID of the user
 */
function EmailVerificationModal({
  show,
  close,
  onVerify,
  title,
  analysisId,
  userId,
}: {
  show: boolean;
  close: () => void;
  onVerify: (integrationType: string) => Promise<void>;
  title?: string;
  analysisId: string;
  userId: string;
}) {
  const { t } = useTranslation("Component.EmailVerificationModal");
  const [selectedIntegration, setSelectedIntegration] = useState("");
  const [loading, setLoading] = useState(false);
  const [validations, setValidations] = useState<any[]>([]);
  const [validationsLoading, setValidationsLoading] = useState(false);
  const [activeTab, setActiveTab] = useState("validations");
  
  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [hasMore, setHasMore] = useState(false);
  const itemsPerPage = 5; // Number of validations per page

  // Filter integrations to only include those of type "validation", published and not comming soon
  const validationIntegrations = integrations.filter(
    (integration) => integration.integrationType === "validation" && integration.published && !integration.commingSoon
  );

  const [dropdownOpen, setDropdownOpen] = useState(false);

  /**
   * Fetch validations for the current analysis with pagination
   */
  const fetchValidations = useCallback(async (page = 1) => {
    if (!userId || !analysisId || !show) return;

    try {
      setValidationsLoading(true);
      // Calculate pagination limits
      const startIndex = (page - 1) * itemsPerPage;
      const endIndex = startIndex + itemsPerPage;
      
      // Get all validations first (we'll implement proper server-side pagination later)
      const allValidations = await getValidationsByAnalysis(userId, analysisId);
      
      // Calculate total pages
      const total = Math.ceil(allValidations.length / itemsPerPage);
      setTotalPages(total > 0 ? total : 1);
      
      // Check if there are more pages
      setHasMore(endIndex < allValidations.length);
      
      // Get current page items
      const paginatedValidations = allValidations.slice(startIndex, endIndex);
      setValidations(paginatedValidations);
      setCurrentPage(page);
    } catch (err: unknown) {
      if (err instanceof Error) {
        toast.error(t("errors.fetchFailed") + ": " + err.message);
      } else {
        toast.error(t("errors.unknown"));
      }
    } finally {
      setValidationsLoading(false);
    }
  }, [userId, analysisId, show, t, itemsPerPage]);

  // Fetch validations when the modal is opened or page changes
  useEffect(() => {
    if (show) {
      fetchValidations(currentPage);
    }
  }, [show, fetchValidations, currentPage]);

  const handleIntegrationSelect = (slug: string) => {
    setSelectedIntegration(slug);
    setDropdownOpen(false);
  };

  const handleVerify = async () => {
    if (!selectedIntegration) return;

    setLoading(true);
    try {
      await onVerify(selectedIntegration);
      close();
    } finally {
      setLoading(false);
    }
  };

  /**
   * Get the appropriate badge class based on validation status
   */
  const getStatusBadgeClass = (status: string): string => {
    switch (status) {
      case "processing":
        return "warning";
      case "error":
        return "danger";
      case "completed":
        return "success";
      default:
        return "secondary";
    }
  };

  /**
   * Get the appropriate status label based on validation status
   */
  const getStatusLabel = (status: string): string => {
    switch (status) {
      case "processing":
        return t("statusProcessing");
      case "error":
        return t("statusError");
      case "completed":
        return t("statusFinished");
      default:
        return status;
    }
  };

  return (
    <Modal show={show} onHide={close} size="lg">
      <Modal.Header>
        <Modal.Title>{title || t("title")}</Modal.Title>
        {activeTab === "validations" && validations.length > 0 && (
          <Button variant="primary" size="sm" onClick={() => setActiveTab("newValidation")} className="ml-auto">
            <PlusCircle className="mr-1" />
            <span className="align-middle">{t("new")}</span>
          </Button>
        )}
      </Modal.Header>
      <Modal.Body className="pt-4">
        {activeTab === "validations" ? (
          <>
            {validationsLoading ? (
              <div className="d-flex justify-content-center align-items-center" style={{ height: "200px" }}>
                <div className="spinner-border text-primary" role="status">
                  <span className="visually-hidden"></span>
                </div>
              </div>
            ) : validations.length === 0 ? (
              <div className="text-center py-4">
                <img
                  src="/assets/svg/illustrations/oc-empty-cart.svg"
                  alt="Empty"
                  className="img-fluid mb-4"
                  style={{ maxWidth: "8rem" }}
                />
                <p className="mb-0 text-center mb-4">{t("noValidations")}</p>
                <Button variant="primary" onClick={() => setActiveTab("newValidation")}>
                  <PlusCircle className="mr-1" />
                  <span className="align-middle">{t("new")}</span>
                </Button>
              </div>
            ) : (
              <div className="d-flex flex-column">
                <div className="table-responsive" style={{ maxHeight: "350px", overflowY: "auto" }}>
                  <table className="table table-sm table-borderless table-nowrap table-align-middle">
                    <thead className="thead-light font-weight-light" style={{ position: "sticky", top: 0, zIndex: 1 }}>
                      <tr>
                        <th></th>
                        <th>{t("provider")}</th>
                        <th>{t("status")}</th>
                        <th>{t("createdAt")}</th>
                        <th>{t("statistics")}</th>
                        <th>{t("actions")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {validations.map((item: any, index: number) => {
                      return (
                        <tr key={index}>
                          <td className="text-center">
                            {item.status === "completed" ? (
                              <CheckCircle className="text-success" />
                            ) : item.status === "error" ? (
                              <ExclamationTriangleFill className="text-danger" />
                            ) : (
                              <div className="spinner-border spinner-border-sm text-warning" role="status">
                                <span className="visually-hidden"></span>
                              </div>
                            )}
                          </td>
                          <td>{capitalizeString(item.provider || "")}</td>
                          <td>
                            <span className={`badge bg-soft-${getStatusBadgeClass(item.status)} text-${getStatusBadgeClass(item.status)}`}>
                              {getStatusLabel(item.status)}
                            </span>
                          </td>
                          <td>
                            {item.createdAt && (
                              <Moment format="DD/MM/YYYY HH:mm">
                                {typeof item.createdAt.toDate === "function" ? item.createdAt.toDate() : item.createdAt}
                              </Moment>
                            )}
                          </td>
                          <td>
                            {item.statistics ? (
                              <div>
                                <span className="badge bg-soft-success text-success mr-1">
                                  {t("valid")}: {item.statistics.valid || 0}
                                </span>
                                <span className="badge bg-soft-danger text-danger mr-1">
                                  {t("invalid")}: {item.statistics.invalid || 0}
                                </span>
                                <span className="badge bg-soft-warning text-warning">
                                  {t("unknown")}: {item.statistics.unknown || 0}
                                </span>
                              </div>
                            ) : (
                              "-"
                            )}
                          </td>
                          <td>
                            <Link to={`/analysis/${analysisId}/validations/${item.id}`} className="btn btn-white btn-xs" onClick={close}>
                              <Eye />
                              <span className="align-middle ml-1">{t("view")}</span>
                            </Link>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
                </div>
                
                {/* Pagination controls */}
                <div className="d-flex justify-content-between align-items-center mt-3">
                  <div className="small text-muted">
                    {t("page")} {currentPage} {t("of")} {totalPages}
                  </div>
                  <div className="btn-group">
                    <button 
                      className="btn btn-sm btn-white" 
                      onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
                      disabled={currentPage === 1 || validationsLoading}
                    >
                      {t("previous")}
                    </button>
                    <button 
                      className="btn btn-sm btn-white" 
                      onClick={() => setCurrentPage(prev => prev + 1)}
                      disabled={!hasMore || validationsLoading}
                    >
                      {t("next")}
                    </button>
                  </div>
                </div>
              </div>
            )}
          </>
        ) : (
          <>
            <div className="mb-3">
              <label className="form-label">{t("selectService")}</label>
              <div className="dropdown">
                <button
                  className="btn btn-white dropdown-toggle w-100 text-left d-flex justify-content-between align-items-center"
                  type="button"
                  id="dropdownMenuButtonWhite"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  onClick={() => setDropdownOpen(!dropdownOpen)}
                  disabled={loading}
                  aria-expanded={dropdownOpen}
                >
                  <span>
                    {selectedIntegration ? validationIntegrations.find((i) => i.slug === selectedIntegration)?.name : t("selectOption")}
                  </span>
                </button>
                <div className={`dropdown-menu w-100 ${dropdownOpen ? "show" : ""}`} aria-labelledby="dropdownMenuButton">
                  {validationIntegrations.map((integration) => (
                    <div
                      key={integration.id}
                      className="dropdown-item px-3"
                      onClick={() => handleIntegrationSelect(integration.slug)}
                      style={{ cursor: "pointer" }}
                    >
                      <div className="d-flex align-items-center">
                        <img className="avatar avatar-xss mr-2" src={integration.image} alt={integration.name} />
                        <span className="align-middle">{integration.name}</span>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-between mt-4">
              <Button variant="white" onClick={() => setActiveTab("validations")}>
                <ArrowLeft className="mr-1" />
                <span className="align-middle">{t("back")}</span>
              </Button>
              <Button variant="primary" onClick={handleVerify} disabled={loading || !selectedIntegration}>
                {loading ? (
                  <>
                    <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true" />
                    {t("verifying")}
                  </>
                ) : (
                  t("verify")
                )}
              </Button>
            </div>
          </>
        )}
      </Modal.Body>
    </Modal>
  );
}

export default EmailVerificationModal;
