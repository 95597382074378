import { useContext, useEffect, useState } from "react";
import toast from "react-hot-toast";
import Breadcumb from "../../components/Breadcumb";
import { useNavigate, useParams, Navigate } from "react-router-dom";
import { auth } from "../../lib/firebase/firebase";
import SendExample from "./SendExample";
import GenerateEmailModal from "./GenerateEmailModal";
import { createEmail, listenEmail, updateEmail } from "../../lib/firebase/emails";
import Header from "../../components/Header";
import TemplatesModal from "./TemplatesModal";
import { UserContext } from "../../contexts/UserContext";
import PremiumModal from "../../components/PremiumModal";
import { Email } from "../../types/EmailTypes";
import EmailCreator from "./EmailCreator";
import { STATUS } from "../../utils/constants";
import { ProjectContext } from "../../contexts/ProjectContext";
import { useTranslation } from "react-i18next";
import Scheduling from "./Scheduling";
import { pauseCampaign, startCampaign } from "../../api/mailerfind/email";
import ConfirmModal from "../../components/ConfirmModal";
import { EnvelopeCheck, PauseCircle, PlayCircle, SendFill } from "react-bootstrap-icons";
import { Button, ButtonGroup, Dropdown, DropdownButton, DropdownDivider } from "react-bootstrap";
import useEmailValidation from "../../hooks/useEmailValidation";
import EmailVerificationModal from "../../components/EmailVerificationModal";
import IntegrationInfoModal from "../../components/IntegrationInfoModal";

function NewEmail() {
  const { id } = useParams() as any;
  const { t } = useTranslation("NewEmail.index");
  const [loading, setLoading] = useState(true);
  const [authUser] = useState(auth.currentUser) as any;
  const [saved, setSaved] = useState(true);
  const [show, setShow] = useState(false);
  const [emailAiMode, setEmailAiMode] = useState("" as any);
  const { user } = useContext(UserContext) as any;
  const { project } = useContext(ProjectContext) as any;
  const [activeTab, setActiveTab] = useState("email");
  const [showPremiumModal, setShowPremiumModal] = useState(false);
  const [showGenerateModal, setShowGenerateModal] = useState(false);
  const [showTemplatesModal, setShowTemplatesModal] = useState(false);
  const [confirmIsLoading, setConfirmIsLoading] = useState(false);
  const [email, setEmail] = useState<Email>({
    version: 3,
    title: "",
    subject: "",
    body: "",
    analysis: null,
    createdAt: new Date(),
    sent: 0,
    opens: 0,
    clicks: 0,
    uniqueClicks: 0,
    skipped: 0,
    unsubscribed: 0,
    senders: null,
    followUp: {
      isAFollowUpEmail: false,
      followUpMode: null,
      email: null,
    },
    scheduleDays: {
      mon: true,
      tue: true,
      wed: true,
      thu: true,
      fri: true,
      sat: false,
      sun: false,
    },
    status: STATUS.NOT_STARTED,
    project: project?.id || null,
  });

  // Estado para el modal de confirmación
  const [showEmailVerificationModal, setShowEmailVerificationModal] = useState(false);
  const { redirectToValidation, startEmailValidation, showIntegrationModal, setShowIntegrationModal, selectedIntegration } =
    useEmailValidation(user?.firebaseUser?.uid || "", project?.id || null, "NewEmail.index");

  const [confirmModal, setConfirmModal] = useState<{ action: "start" | "pause" | null; show: boolean }>({
    action: null,
    show: false,
  });

  const navigate = useNavigate();

  // Render navigation component when redirectToValidation is set
  const renderRedirect = () => {
    if (redirectToValidation) {
      return <Navigate to={redirectToValidation} />;
    }
    return null;
  };

  useEffect(() => {
    if (!id) return setLoading(false);
    const unsubscribe = listenEmail(
      user.firebaseUser.uid,
      id,
      (emailData) => {
        if (emailData) setEmail(emailData);
        setLoading(false);
      },
      (error) => toast.error(error.message)
    );
    return () => unsubscribe();
  }, [id, user.firebaseUser.uid]);

  const handleSubmit = async (e: any) => {
    e && e.preventDefault();
    if (!email.subject) return toast.error(t("subjectRequired"));
    if (!email.title) return toast.error(t("titleRequired"));
    if (!email.senders || email.senders.length === 0) return toast.error(t("sendersRequired"));

    if (email?.followUp?.isAFollowUpEmail) {
      if (!email.followUp.email) return toast.error(t("followUpEmailRequired"));
      if (!email.followUp.followUpMode) return toast.error(t("followUpModeRequired"));
    }

    if (!email.analysis) return toast.error(t("analysisRequired"));

    if (id) {
      email.id = null;

      // trim the body, the subject and the title
      email.title = email.title.trim();
      email.body = email.body.trim();
      email.subject = email.subject.trim();

      try {
        setLoading(true);
        console.log(email);
        await updateEmail(authUser.uid, id, email);
        email.id = id; // restore
        toast.success(t("emailUpdatedSuccessfully"));
        setSaved(true);
      } catch (error: any) {
        if (error.message.includes("INVALID_ARGUMENT")) {
          toast.error(t("highEmailSize"));
        } else toast.error(error.message || t("somethingWentWrong"));
      } finally {
        setLoading(false);
      }
    } else {
      try {
        setLoading(true);
        const id = await createEmail(authUser.uid, email);
        toast.success(t("emailCreatedSuccessfully"));
        setSaved(true);
        navigate(`/emails/${id}/edit`);
      } catch (error: any) {
        if (error.message.includes("INVALID_ARGUMENT")) {
          toast.error(t("highEmailSize"));
        } else toast.error(error.message || t("somethingWentWrong"));
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    const handleKeyDown = (event: any) => {
      if ((event.metaKey || event.ctrlKey) && event.key === "s") {
        event.preventDefault();
        handleSubmit(null);
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    // Limpiar el evento cuando el componente se desmonte
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [email]);

  const handleStartCampaign = async (id: string) => {
    try {
      const jwt = await auth.currentUser!.getIdToken();
      await startCampaign(id, jwt);
    } catch (err: any) {
      toast.error(err.message);
    }
  };

  const handlePauseCampaign = async (id: string) => {
    try {
      const jwt = await auth.currentUser!.getIdToken();
      await pauseCampaign(id, jwt);
    } catch (err: any) {
      toast.error(err.message);
    }
  };

  const handleOnResponse = (text: any) => {
    // Aquí actualizas el formulario con el texto generado por la IA.
    setEmail({ ...email, body: text });
    setShow(false);
    setShowTemplatesModal(false);
  };

  const openPopUp = () => {
    setShow(true);
  };

  const openEmailAiModal = (mode: string) => {
    if (user.firebaseUser.plan === "free") {
      setShowPremiumModal(true);
      return;
    }
    setEmailAiMode(mode);
    setShowGenerateModal(true);
  };

  const handleConfirmAction = async () => {
    if (!id || !confirmModal.action) return;
    if (user.firebaseUser.plan === "free") {
      setConfirmModal({ action: null, show: false });
      setShowPremiumModal(true);
      return;
    }
    setConfirmIsLoading(true);
    if (confirmModal.action === "start") {
      await handleStartCampaign(id);
    } else if (confirmModal.action === "pause") {
      await handlePauseCampaign(id);
    }
    setConfirmIsLoading(false);
    setConfirmModal({ action: null, show: false });
  };

  return (
    <div>
      {renderRedirect()}
      <Breadcumb
        items={[
          { title: t("emails"), to: "/emails" },
          { title: t("newEmail"), to: "#" },
        ]}
      />
      <Header title={t("editEmail")} subtitle={email.title || t("emailTitle")}>
        <ButtonGroup id="email-actions-btn-group">
          <Button variant="primary" onClick={handleSubmit} disabled={saved || loading} id="save-email-btn">
            {t("saveChanges")}
          </Button>

          <DropdownButton as={ButtonGroup} title="" id="bg-nested-dropdown" disabled={!saved || loading || !id}>
            <Dropdown.Item eventKey="1" onClick={openPopUp} className="px-3" id="send-email-example-btn">
              <SendFill className="mr-1" /> <span className="px-0 align-middle">{t("sendExample")}</span>
            </Dropdown.Item>

            {["NOT_STARTED", "PAUSED", "ERROR"].includes(email.status) && (
              <Dropdown.Item
                eventKey="2"
                onClick={() => setConfirmModal({ action: "start", show: true })}
                className="px-3"
                id="send-email-btn"
              >
                <PlayCircle className="mr-1" /> <span className="px-0 align-middle">{t("send")}</span>
              </Dropdown.Item>
            )}

            {email.status === "RUNNING" && (
              <Dropdown.Item eventKey="3" onClick={() => setConfirmModal({ action: "pause", show: true })} className="px-3">
                <PauseCircle className="mr-1" /> <span className="px-0 align-middle">{t("pause")}</span>
              </Dropdown.Item>
            )}

            <DropdownDivider />

            <Dropdown.Item eventKey="4" onClick={() => setShowEmailVerificationModal(true)} className="px-3" disabled={!email.analysis}>
              <EnvelopeCheck className="mr-1" /> <span className="px-0 align-middle">{t("emailValidation")}</span>
            </Dropdown.Item>
          </DropdownButton>
        </ButtonGroup>
        {["STARTING", "PAUSING"].includes(email.status) && (
          <button className="btn btn-outline-primary px-10" disabled>
            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
          </button>
        )}
      </Header>

      <ul className="nav nav-tabs mb-4">
        <li className="nav-item">
          <span
            className={`nav-link ${activeTab === "email" && "active"}`}
            onClick={() => setActiveTab("email")}
            style={{ cursor: "pointer" }}
          >
            Email
          </span>
        </li>
        <li className="nav-item">
          <span
            className={`nav-link ${activeTab === "scheduling" && "active"}`}
            onClick={() => setActiveTab("scheduling")}
            style={{ cursor: "pointer" }}
          >
            Scheduling
          </span>
        </li>
      </ul>

      {activeTab === "email" && (
        <EmailCreator
          id={id}
          loading={loading}
          handleSubmit={handleSubmit}
          setSaved={setSaved}
          saved={saved}
          email={email}
          setEmail={setEmail}
          openEmailAiModal={openEmailAiModal}
          openTemplatesModal={() => setShowTemplatesModal(true)}
        />
      )}

      {activeTab === "scheduling" && <Scheduling email={email} setEmail={setEmail} setSaved={setSaved} loading={loading} />}

      <SendExample emailData={email} show={show} handleClose={() => setShow(false)} setShow={setShow} sendTo={user.firebaseUser.email} />

      <GenerateEmailModal
        show={showGenerateModal}
        mode={emailAiMode}
        body={email.body}
        handleClose={() => setShowGenerateModal(false)}
        onResponse={handleOnResponse}
        emailId={id}
      />

      <TemplatesModal show={showTemplatesModal} handleClose={() => setShowTemplatesModal(false)} onResponse={handleOnResponse} />
      <PremiumModal show={showPremiumModal} setShow={setShowPremiumModal} title={t("upgradePlan")} />

      <EmailVerificationModal
        show={showEmailVerificationModal}
        close={() => setShowEmailVerificationModal(false)}
        onVerify={async (integration) => {
          await startEmailValidation(email.analysis || "", integration);
        }}
        analysisId={email.analysis || ""}
        userId={user?.firebaseUser?.uid || ""}
      />

      <IntegrationInfoModal show={showIntegrationModal} setShow={setShowIntegrationModal} integrationType={selectedIntegration} />

      <ConfirmModal
        show={confirmModal.show}
        setShow={(show: boolean) => setConfirmModal({ ...confirmModal, show })}
        onConfirm={handleConfirmAction}
        loading={confirmIsLoading}
        text={confirmModal.action === "start" ? t("confirmStartSending") : confirmModal.action === "pause" ? t("confirmPauseSending") : ""}
      />
    </div>
  );
}

export default NewEmail;
