import React from "react";
import Header from "../../components/Header";
import LiveEventCard from "./LiveEventCard";

const liveEvents = [
  {
    id: 1,
    title: "Diseño de estrategia y Q&A",
    description:
      "¿No sabes por dónde empezar? En esta demo en vivo, aprenderás a crear tu estrategia, estructurar tu funnel y convertir desconocidos en clientes. También podrás resolver tus dudas en directo durante el Q&A.",
    recurrence: {
      day: 2, // Martes (en JS: domingo=0, lunes=1, martes=2, etc.)
      time: "17:00",
      timeZone: "Europe/Madrid",
    },
    category: "Marketing",
    image: "/assets/img/live-events/demo-en-vivo-nico.jpg",
    status: "published",
    language: "es",
    src: "https://cal.com/nico-roca/demo",
  },
  {
    id: 2,
    title: "Crea un mensaje que venda",
    description:
      "Aprende a crear correos que consigan respuestas con técnicas de copywriting y estructuras ganadoras. Verás ejemplos reales de lo que funciona (y lo que no), y podrás traer tu propio correo para revisarlo en vivo y mejorarlo.",
    recurrence: {
      day: 3, // Miércoles
      time: "17:00",
      timeZone: "Europe/Madrid",
    },
    category: "Copywriting",
    image: "/assets/img/live-events/crea-un-mensaje-que-venda.jpg",
    status: "published",
    language: "es",
    src: "https://cal.com/nico-roca/webinar-copywriting",
  },
  {
    id: 3,
    title: "Optimiza la entrega de tus emails y evita el spam",
    description:
      "Un experto en entregabilidad te guiará para asegurar que tus correos lleguen a la bandeja de entrada y evitar el spam. Resuelve tus dudas técnicas en vivo o compártenos si te has quedado atascado en algún paso.",
    recurrence: {
      day: 4, // Jueves
      time: "17:00",
      timeZone: "Europe/Madrid",
    },
    category: "Deliverability",
    image: "/assets/img/live-events/demo-en-vivo.png",
    status: "draft",
    language: "es",
    src: "https://www.youtube.com/embed/1",
  },
];

const LiveEvents: React.FC = () => {
  return (
    <>
      <Header title="Eventos en vivo" subtitle="" breadCrumbItems={[{ title: "Eventos en Vivo", href: "/live-events" }]} />
      <div className="row">
        {liveEvents.map((liveEvent) => (
          <>{liveEvent.status === "published" && <LiveEventCard key={liveEvent.id} liveEvent={liveEvent} />}</>
        ))}
      </div>
    </>
  );
};

export default LiveEvents;
