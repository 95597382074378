import { addDoc, collection, deleteDoc, doc, getDoc, getDocs, onSnapshot, orderBy, query, updateDoc, where } from "firebase/firestore";
import { db } from "./firebase";
import { Email } from "../../types/EmailTypes";
import { pick } from "../../utils/pick";

export const getEmail = async (userId: string, emailId: string) => {
  const userRef = doc(db, "users", userId);
  const emailRef = doc(userRef, "emails", emailId);
  const docSnap = await getDoc(emailRef);
  if (!docSnap.exists()) return null;
  const data = docSnap.data();
  delete data.id; // Elimina el ID del documento
  const email: Email = {
    version: data.version,
    title: data.title,
    subject: data.subject,
    body: data.body,
    senders: data.senders,
    analysis: data.analysis, // Asegúrate de que el tipo de dato coincida o transformalo aquí
    createdAt: data.createdAt.toDate(), // Convertimos de Timestamp de Firestore a Date de JavaScript
    sent: data.sent,
    opens: data.opens,
    clicks: data.clicks,
    uniqueClicks: data.uniqueClicks || 0,
    unsubscribed: data.unsubscribed || 0,
    skipped: data.skipped || 0,
    errorMessage: data.errorMessage ?? null,
    timeZone: data.timeZone ?? null,
    followUp: {
      isAFollowUpEmail: data.followUp?.isAFollowUpEmail ?? false, // Asegúrate de manejar los valores predeterminados correctamente
      followUpMode: data.followUp?.followUpMode ?? null,
      email: data.followUp?.email ?? null,
    },
    status: data.status,
    id: docSnap.id, // Agregamos el ID si es necesario en tu FormData
    project: data.project,
  };
  return email;
};

export const listenEmail = (userId: string, emailId: string, onUpdate: (email: Email | null) => void, onError: (error: Error) => void) => {
  // Referencia al documento del usuario
  const userRef = doc(db, "users", userId);
  // Referencia al subdocumento del email dentro del usuario
  const emailRef = doc(userRef, "emails", emailId);

  // Suscripción en tiempo real al documento del email
  const unsubscribe = onSnapshot(
    emailRef,
    (docSnap) => {
      // Si el documento no existe, devolvemos null
      if (!docSnap.exists()) {
        onUpdate(null);
        return;
      }

      const data = docSnap.data();
      // Eliminamos la propiedad "id" en caso de que esté presente en los datos
      delete data.id;

      // Transformación de los datos del documento a nuestro tipo Email
      const email: Email = {
        version: data.version,
        title: data.title,
        subject: data.subject,
        body: data.body,
        senders: data.senders,
        analysis: data.analysis, // Asegúrate de que el tipo coincide o realiza la transformación necesaria
        createdAt: data.createdAt.toDate(), // Conversión de Timestamp a Date
        sent: data.sent,
        opens: data.opens,
        clicks: data.clicks,
        uniqueClicks: data.uniqueClicks || 0,
        unsubscribed: data.unsubscribed || 0,
        skipped: data.skipped || 0,
        errorMessage: data.errorMessage ?? null,
        timeZone: data.timeZone ?? null,
        followUp: {
          isAFollowUpEmail: data.followUp?.isAFollowUpEmail ?? false,
          followUpMode: data.followUp?.followUpMode ?? null,
          email: data.followUp?.email ?? null,
        },
        scheduleDays: data.scheduleDays ?? null,
        status: data.status,
        id: docSnap.id, // Asignamos el ID del documento
        project: data.project,
      };

      // Ejecutamos el callback pasando el email transformado
      onUpdate(email);
    },
    // Callback de error en caso de que ocurra algún fallo durante la escucha
    (err) => {
      onError(err);
    }
  );

  // Devolvemos la función para cancelar la suscripción
  return unsubscribe;
};

export const getEmails = async (userId: any, orderByParam = null as any, filterParams = null as any) => {
  const userRef = doc(db, "users", userId);
  const emailsRef = collection(userRef, "emails");

  let q = query(emailsRef);

  // Aplicar filtros si se proporcionan
  if (filterParams) {
    const whereClauses = [];
    for (const [field, values] of Object.entries(filterParams) as any) {
      if (Array.isArray(values) && (values.length > 10 || values.length === 0)) {
        // Manejar el caso donde hay más de 10 valores.
        // Firestore 'in' no soporta más de 10 valores.
        // Podrías dividir esto en múltiples consultas o reconsiderar el enfoque.
      } else {
        // Usa 'in' para crear una condición que matchee cualquiera de los valores especificados.
        if (Array.isArray(values)) {
          whereClauses.push(where(field, "in", values));
        } else {
          values !== undefined && whereClauses.push(where(field, "==", values));
        }
      }
    }

    // Si se proporciona orderByParam, incluirlo en la consulta
    if (orderByParam) {
      if (Array.isArray(orderByParam) && orderByParam.length === 2) {
        q = query(emailsRef, ...whereClauses, orderBy(orderByParam[0], orderByParam[1]));
      } else {
        // Si orderByParam es un string, poner orden descendente
        q = query(emailsRef, ...whereClauses, orderBy(orderByParam, "desc"));
      }
    } else {
      // Si no se proporciona orderByParam, crea la consulta solo con los filtros
      q = query(emailsRef, ...whereClauses);
    }
  } else {
    // Si no se proporcionan filtros pero sí ordenamiento
    if (orderByParam) {
      if (Array.isArray(orderByParam) && orderByParam.length === 2) {
        q = query(emailsRef, orderBy(orderByParam[0], orderByParam[1]));
      } else {
        q = query(emailsRef, orderBy(orderByParam, "desc"));
      }
    }
  }

  const querySnapshot = await getDocs(q);
  const emails = [] as any;
  querySnapshot.forEach((doc) => {
    const email = doc.data();
    email.id = doc.id;
    emails.push(email);
  });
  return emails;
};

export const listenEmails = (
  userId: string,
  orderByParam: string | [string, "asc" | "desc"] | null = null,
  filterParams: Record<string, any> | null = null,
  onUpdate: (emails: any[]) => void,
  onError?: (error: any) => void
) => {
  const userRef = doc(db, "users", userId);
  const emailsRef = collection(userRef, "emails");

  let q = query(emailsRef);

  if (filterParams) {
    const whereClauses = [];
    for (const [field, values] of Object.entries(filterParams)) {
      if (Array.isArray(values) && values.length === 0) {
        console.warn(`Field "${field}" has 0 values. Skipping filter.`);
        continue; // Saltar el filtro si el array está vacío
      } else if (Array.isArray(values)) {
        whereClauses.push(where(field, "in", values));
      } else if (values !== undefined) {
        whereClauses.push(where(field, "==", values));
      }
    }

    if (orderByParam) {
      if (Array.isArray(orderByParam) && orderByParam.length === 2) {
        q = query(emailsRef, ...whereClauses, orderBy(orderByParam[0], orderByParam[1]));
      } else {
        q = query(emailsRef, ...whereClauses, orderBy(orderByParam, "desc"));
      }
    } else {
      q = query(emailsRef, ...whereClauses);
    }
  } else {
    if (orderByParam) {
      if (Array.isArray(orderByParam) && orderByParam.length === 2) {
        q = query(emailsRef, orderBy(orderByParam[0], orderByParam[1]));
      } else {
        q = query(emailsRef, orderBy(orderByParam, "desc"));
      }
    }
  }

  // Establecer la suscripción en tiempo real
  const unsubscribe = onSnapshot(
    q,
    (snapshot) => {
      const emails = snapshot.docs.map((doc) => {
        const data = doc.data();
        return { ...data, id: doc.id }; // Asignar `id` explícitamente al final
      });
      onUpdate(emails);
    },
    (error) => {
      console.error("Error listening to emails:", error);
      if (onError) onError(error);
    }
  );

  // Devuelve la función para cancelar la suscripción
  return unsubscribe;
};

export const createEmail = async (userId: string, email: Email): Promise<string> => {
  const userRef = doc(db, "users", userId);
  const { id } = await addDoc(collection(userRef, "emails"), email);
  return id;
};

/**
 * Actualiza un documento de email en Firestore, seleccionando únicamente las propiedades permitidas.
 *
 * @param {any} userId - ID del usuario propietario del email.
 * @param {any} emailId - ID del email a actualizar.
 * @param {any} email - Objeto con las propiedades del email a actualizar.
 * @returns {Promise<void>} Una promesa que indica el estado de la actualización.
 * @throws {Error} Si ocurre un error durante la actualización.
 */
export const updateEmail = async (userId: any, emailId: any, email: any): Promise<void> => {
  // Array de propiedades permitidas
  const allowedProperties = [
    "title",
    "subject",
    "body",
    "analysis",
    "senders",
    "followUp",
    "status",
    "project",
    "timeZone",
    "scheduleDays",
  ] as const;

  // Convertir el array readonly en un array mutable
  const mutableAllowedProperties = [...allowedProperties];

  // Referencia al documento que quieres actualizar
  const emailRef = doc(db, "users", userId, "emails", emailId);

  // Filtrar las propiedades permitidas del objeto email
  const filteredEmail = pick(email, mutableAllowedProperties);

  // Actualizar el documento
  try {
    await updateDoc(emailRef, filteredEmail);
  } catch (error) {
    console.error("Error updating email: ", error);
    throw error;
  }
};

export const deleteEmail = async (userId: any, emailId: any) => {
  const userRef = doc(db, "users", userId);
  const emailRef = doc(userRef, "emails", emailId);
  await deleteDoc(emailRef);
};

export const duplicateEmail = async (userId: string, emailId: string): Promise<string | null> => {
  const userRef = doc(db, "users", userId);
  const emailRef = doc(userRef, "emails", emailId);
  const docSnap = await getDoc(emailRef);

  if (!docSnap.exists()) return null;

  const email: Email = { ...docSnap.data(), id: docSnap.id } as Email;

  // Define los campos que deben resetearse
  email.title = `${email.title} (copy)`;
  email.createdAt = new Date();
  email.clicks = 0;
  email.opens = 0;
  email.uniqueClicks = 0;
  email.sent = 0;
  email.skipped = 0;
  email.status = "NOT_STARTED";

  const newEmailId = await createEmail(userId, email);
  return newEmailId;
};
