import React, { useState } from "react";
import { Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import { QuestionCircle, Star, StarFill } from "react-bootstrap-icons";
import toast from "react-hot-toast";
import { redeemCredits } from "../api/mailerfind/users";
import { auth } from "../lib/firebase/firebase";
import { useTranslation } from "react-i18next";

function Rating({ show, setShow, onComplete }: any) {
  const { t } = useTranslation("Component.Rating");
  const [rating, setRating] = useState(0);
  const [hover, setHover] = useState(0);
  const [showTextArea, setShowTextArea] = useState(false);
  const [showReviewURLField, setShowReviewURLField] = useState(false);
  const [review, setReview] = useState("");
  const [reviewerName, setReviewerName] = useState("");
  const [loading, setLoading] = useState(false);

  const handleClose = () => setShow(false);

  const handleRating = async (rate: number) => {
    setRating(rate);
    if (rate > 3) {
      window.open("https://g.page/r/CTUDqX-cUK1KEB0/review", "_blank");
      setShowReviewURLField(true);
      setShowTextArea(false);
    } else {
      setShowTextArea(true);
      setShowReviewURLField(false);
    }
  };

  const getRatingLabel = (rate: number) => {
    switch (rate) {
      case 1:
        return t("veryBad");
      case 2:
        return t("bad");
      case 3:
        return t("average");
      case 4:
        return t("good");
      case 5:
        return t("excellent");
      default:
        return "";
    }
  };

  const handleReviewSubmit = async () => {
    try {
      setLoading(true);
      const jwt = (await auth.currentUser?.getIdToken()) as string;
      const bodyContent = {
        content: review || `Review left on Google as ${reviewerName}`,
        rating: rating,
      };
      await redeemCredits("googleReview", bodyContent, jwt);
      toast.success(t("reviewSubmitted"));
      onComplete();
      handleClose();
    } catch (err: any) {
      toast.error(err.message);
    } finally {
      setLoading(false);
    }
  };

  const googleAccountNameTooltip = (
    <Tooltip id="tooltip">
      <img src="/assets/img/google.jpg" alt="google" className="img-fluid my-2 rounded" />
      {t("googleTooltip")}
    </Tooltip>
  );

  return (
    <div>
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Body className="py-6 px-5">
          <div className="text-center">
            <h2>{t("experienceQuestion")}</h2>
            <p>{t("leaveReview")}</p>
            <div id="">
              {[1, 2, 3, 4, 5].map((index) => (
                <span
                  key={index}
                  className="text-primary"
                  style={{ cursor: "pointer" }}
                  onMouseEnter={() => setHover(index)}
                  onMouseLeave={() => setHover(rating)}
                  onClick={() => handleRating(index)}
                >
                  {index <= (hover || rating) ? <StarFill size={30} /> : <Star size={30} />}
                </span>
              ))}
              <div style={{ minHeight: "20px", marginTop: "10px" }}>{getRatingLabel(hover || rating)}</div>
            </div>
          </div>
          <div>
            {showTextArea && (
              <>
                <textarea
                  className="form-control my-3"
                  placeholder={t("textAreaPlaceholder")}
                  onChange={(e) => setReview(e.target.value)}
                ></textarea>
                <button className="btn btn-primary btn-block" onClick={handleReviewSubmit} disabled={loading}>
                  {loading ? t("submitting") : t("submit")}
                </button>
              </>
            )}
            {showReviewURLField && (
              <>
                <label className="mt-3 mr-2">{t("googleAccountName")}</label>
                <OverlayTrigger placement="left" overlay={googleAccountNameTooltip}>
                  <QuestionCircle size="13" style={{ cursor: "pointer" }} />
                </OverlayTrigger>
                <input
                  type="text"
                  className="form-control mb-3"
                  placeholder="John Doe"
                  value={reviewerName}
                  onChange={(e) => setReviewerName(e.target.value)}
                />
                <button className="btn btn-primary btn-block" onClick={handleReviewSubmit} disabled={loading}>
                  {loading ? t("submitting") : t("submitReview")}
                </button>
              </>
            )}
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default Rating;
