import React, { useState, useEffect, useContext } from "react";
import { formatFollowers } from "../../utils/numberFormatter";
import ExportLoadingPopUp from "../../components/ExportLoadingPopUp";
import { Download, EnvelopeCheck, EnvelopeDash, EnvelopeExclamation, Search, Sliders, Stars, X } from "react-bootstrap-icons";
import { auth } from "../../lib/firebase/firebase";
import { UserContext } from "../../contexts/UserContext";
import { getAllProspectsInAnalysisWithFilter as getCloudProspects } from "../../lib/firebase/prospects";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import IntegrationInfoModal from "../../components/IntegrationInfoModal";
import { getIntegrationByTypeAndProject } from "../../lib/firebase/integrations";
import { ProjectContext } from "../../contexts/ProjectContext";
import { integrations } from "../../utils/constants";
import ExportPopUp from "./ExportPopUp";
import { exportAnalysis } from "../../api/mailerfind/analysis";

function DataTable(props: any) {
  const [users, setUsers] = useState(props.users);
  const [selectedOption, setSelectedOption] = useState("any");

  const { t } = useTranslation("DataTable");

  const { user } = useContext(UserContext) as any;
  const { project } = useContext(ProjectContext) as any;

  const [showExportPopUp, setShowExportPopUp] = useState(false);
  const [showExportLoadingPopUp, setShowExportLoadingPopUp] = useState(false);
  const [showIntegrationModal, setShowIntegrationModal] = useState(false);
  const [exportFinished, setExportFinished] = useState(false);
  const [format, setFormat] = useState("csv");

  const [selectedIntegration, setSelectedIntegration] = useState<string | null>(null);

  const handleCloseExportLoadingPopUp = () => setShowExportLoadingPopUp(false);
  const handleShowExportLoadingPopUp = () => setShowExportLoadingPopUp(true);

  const MAX_ROWS = 100;

  const handleExport = async (format: string, type: string, isAdvanced: boolean) => {
    /*
     * If the selected format is smartlead, we need to check if the user has the integration enabled.
     * If the integration is not enabled, we show the IntegrationInfoModal.
     */
    if (!isAdvanced) {
      const userId = user?.firebaseUser?.uid;

      if (type === "integration") {
        const integration = await getIntegrationByTypeAndProject(userId, format, project?.id || null);
        if (!integration) {
          setSelectedIntegration(format);
          setShowIntegrationModal(true);
          return;
        }
      }

      handleShowExportLoadingPopUp();
      setExportFinished(false);

      const startExport = async (format: string) => {
        try {
          const jwt = await auth.currentUser!.getIdToken();
          const response = await exportAnalysis(props.analysis.id, ["email"], null, format, jwt);
          if (response.queued) {
            setExportFinished(true);
            return;
          }
          window.open(response.url, "_blank");
          if (!response.queued) handleCloseExportLoadingPopUp();
          return response.queued;
        } catch (err: any) {
          toast.error(err.message || "An error occurred while exporting the data.");
          handleCloseExportLoadingPopUp();
        }
      };

      await startExport(format);
    } else {
      setFormat(format);
      setShowExportPopUp(true);
    }
  };

  const handleSelectChange = (e: any) => {
    setSelectedOption(e.target.value);
  };

  const getProspects = async (analysis: any, filter: string) => {
    if (analysis?.isCloud) return await getCloudProspects(user?.firebaseUser?.uid, analysis.id, filter);
  };

  useEffect(() => {
    let filteredUsers = props.users;
    switch (selectedOption) {
      case "users_with_email":
        getProspects(props.analysis, "email").then((prospects: any) => {
          setUsers(prospects);
        });
        break;
      case "users_with_first_name_and_email":
        getProspects(props.analysis, "name_and_email").then((prospects: any) => {
          setUsers(prospects);
        });
        break;
      case "users_with_phone":
        getProspects(props.analysis, "phone_number").then((prospects: any) => {
          setUsers(prospects);
        });
        break;
      case "users_with_website":
        getProspects(props.analysis, "website").then((prospects: any) => {
          setUsers(prospects);
        });
        break;
      default:
        setUsers(filteredUsers);
        break;
    }
  }, [props.users, selectedOption]);

  const exportOptions = [
    {
      id: "csv",
      label: ".CSV",
      icon: "/assets/svg/components/placeholder-csv-format.svg",
      disabled: false,
    },
    {
      id: "excel",
      label: "Excel",
      icon: "/assets/svg/brands/excel.svg",
      disabled: props.analysis.prospectsCount > 100000,
    },
  ];

  return (
    <>
      <div className="card" style={{ height: 450, overflowY: "auto" }}>
        <div className="card-header">
          <div className="row justify-content-between align-items-center flex-grow-1">
            <div className="col-12 col-md">
              <div className="d-flex justify-content-between align-items-center">
                <h3 className="card-header-title">{t("dataPreview")}</h3>
              </div>
            </div>
          </div>

          <div className="col-md-auto">
            <form>
              {/* Search */}
              <div className="input-group input-group-merge input-group-flush">
                <div className="input-group-prepend">
                  <div className="input-group-text">
                    <Search />
                  </div>
                </div>
                <input
                  id="datatableSearch"
                  type="search"
                  className="form-control"
                  placeholder={t("searchUsers")}
                  aria-label={t("searchUsers")}
                  onChange={props.handleSearchChange}
                />
              </div>
              {/* End Search */}
            </form>
          </div>

          <div className="col-md-auto">
            <div className="form-group mb-0">
              <select
                id="exampleFormControlSelect1"
                className="form-control btn-white text-muted dropdown-toggle"
                onChange={handleSelectChange}
                value={selectedOption}
                disabled={props.users.length === 0}
              >
                <option value="any">{t("showAllUsers")}</option>
                <option value="users_with_email">{t("usersWithEmail")}</option>
                <option value="users_with_first_name_and_email">{t("usersWithEmailAndFirstName")}</option>
                <option value="users_with_phone">{t("usersWithPhone")}</option>
                <option value="users_with_website">{t("usersWithWebsite")}</option>
              </select>
            </div>
          </div>

          <div className="col-md-auto">
            <div className="btn-group">
              <button
                className="btn btn-white dropdown-toggle"
                type="button"
                id="dropdownMenuButtonWhite"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                disabled={props.users.length === 0}
              >
                <span className="mr-2">
                  <Download />
                </span>
                <span className="mr-1">{t("export")}</span>
              </button>
              <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                <span className="dropdown-header px-3" style={{ fontSize: ".65625rem" }}>
                  {t("downloadOptions")}
                </span>

                {exportOptions.map((option: any) => (
                  <div
                    className={`dropdown-item px-3 ${option.disabled ? "disabled" : ""}`}
                    onClick={() => handleExport(option.id, "native", false)}
                    style={{ cursor: "pointer" }}
                  >
                    <img
                      className={`avatar avatar-xss avatar-4by3 mr-2 ${option.disabled ? "opacity-20" : ""}`}
                      src={option.icon}
                      alt={`Download ${option.label}`}
                    />
                    <span className="align-middle">{option.label}</span>
                  </div>
                ))}

                <div className="dropdown-divider"></div>
                <span className="dropdown-header px-3" style={{ fontSize: ".65625rem" }}>
                  {t("integrations")}
                </span>
                {integrations.map((integration) => {
                  return (
                    <>
                      {integration.published && !integration.commingSoon && integration.integrationType === "export" && (
                        <div
                          className="dropdown-item px-3"
                          onClick={() => handleExport(integration.slug, "integration", false)}
                          style={{ cursor: "pointer" }}
                        >
                          <img className="avatar avatar-xss mr-2" src={integration.image} alt={integration.nameKey} />
                          <span className="align-middle">{integration.name}</span>
                        </div>
                      )}
                    </>
                  );
                })}
                <div className="dropdown-divider"></div>
                <span className="dropdown-header px-3" style={{ fontSize: ".65625rem" }}>
                  {t("others")}
                </span>
                <div className="dropdown-item px-3" onClick={() => handleExport("csv", "native", true)} style={{ cursor: "pointer" }}>
                  <Sliders size="14" className="mr-2 ml-1" />
                  <span className="align-middle">{t("advancedExport")}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Table */}
        <div className="table-responsive">
          <table className="table table-lg table-borderless table-thead-bordered table-nowrap table-align-middle">
            <thead className="thead-light" style={{ position: "sticky", top: 0, zIndex: 90 }}>
              <tr>
                <th>{t("nameAndUsername")}</th>
                <th>
                  {t("firstName")}{" "}
                  <OverlayTrigger
                    delay={{ hide: 450, show: 300 }}
                    overlay={(props) => <Tooltip {...props}>{t("tooltipFirstName")}</Tooltip>}
                    placement="bottom"
                  >
                    <Stars size="14" className="mb-1" />
                  </OverlayTrigger>
                </th>
                <th>{t("email")}</th>
                <th>{t("website")}</th>
                <th>{t("phone")}</th>
                <th>{t("following")}</th>
                <th>{t("followers")}</th>
              </tr>
            </thead>
            <tbody>
              {users.slice(0, MAX_ROWS).map((user: any, index: any) => (
                <Row data={user} key={index} />
              ))}
            </tbody>
          </table>
        </div>
        {/* End Table */}
      </div>
      <ExportPopUp show={showExportPopUp} setShow={setShowExportPopUp} analysisId={props.analysis.id} exportOptions={exportOptions} />
      <ExportLoadingPopUp show={showExportLoadingPopUp} finished={exportFinished} close={handleCloseExportLoadingPopUp} />
      <IntegrationInfoModal
        show={showIntegrationModal}
        setShow={() => setShowIntegrationModal(false)}
        integrationType={selectedIntegration}
      />
    </>
  );
}

function Row(props: any) {
  return (
    <tr>
      <td>
        <a className="media align-items-center" href={`https://instagram.com/${props.data.username}`} target="_blank">
          {props.data.profile_pic_urll ? (
            <img
              src={
                "https://corsproxy.io/?https%3A%2F%2Fscontent-waw2-2.cdninstagram.com%2Fv%2Ft51.2885-19%2F342529940_252939000434118_4733278091810776232_n.jpg%3Fstp%3Ddst-jpg_e0_s150x150%26_nc_ht%3Dscontent-waw2-2.cdninstagram.com%26_nc_cat%3D106%26_nc_ohc%3DHR1dGfzmH-AQ7kNvgFensfq%26_nc_gid%3D675bcd6ff74b4cd88f21f3bebcff8e91%26edm%3DAEF8tYYBAAAA%26ccb%3D7-5%26oh%3D00_AYA__zKYpm_gKiqsPiYM9jLRqoAU3QbFA6oKpYg9dk7bBg%26oe%3D66ED04F3%26_nc_sid%3D1e20d2"
              }
              alt="profile"
              className="avatar avatar-sm avatar-circle mr-3"
            />
          ) : (
            <div className="avatar avatar-soft-dark avatar-circle mr-3">
              <span className="avatar-initials">
                {
                  // the first letter of the username in upper case
                  props?.data?.username?.charAt(0).toUpperCase()
                }
              </span>
            </div>
          )}
          <div className="media-body">
            <span className="d-block h5 text-hover-primary mb-0">
              {props.data?.full_name?.slice(0, 25) + (props.data?.full_name?.length > 25 ? "..." : "")}
            </span>
            <span className="d-block font-size-sm text-body">{props.data.username}</span>
          </div>
        </a>
      </td>
      <td>{props.data.first_name || "-"}</td>
      <td>
        {props.data.email && typeof props.data.email === "string" ? (
          <>
            {props.data?.email_status ? (
              <>
                {props.data.email_status === "ok" && (
                  <>
                    <div className="badge bg-soft-success rounded-pill text-success mr-1 px-1">
                      <EnvelopeCheck size={12} />
                    </div>
                    <span className="align-middle">{props.data.email.toLowerCase()}</span>
                  </>
                )}
                {props.data.email_status === "invalid" && (
                  <>
                    <div className="badge bg-soft-danger rounded-pill text-danger mr-1 px-1">
                      <EnvelopeDash size={12} />
                    </div>
                    <span className="align-middle">{props.data.email.toLowerCase()}</span>
                  </>
                )}
                {(props.data.email_status === "unknown" ||
                  props.data.email_status === "catch_all" ||
                  props.data.email_status === "error") && (
                  <>
                    <div className="badge bg-soft-warning rounded-pill text-warning mr-1 px-1">
                      <EnvelopeExclamation size={12} />
                    </div>
                    <span className="align-middle">{props.data.email.toLowerCase()}</span>
                  </>
                )}
              </>
            ) : (
              props.data.email.toLowerCase()
            )}
          </>
        ) : (
          "-"
        )}
      </td>
      <td>
        <a href={props.data.website} target="_blank" rel="noopener">
          {props.data.website
            ? // show the website but crop it to the first 20 chars. if it's longer than 20 chars, add "..."
              props.data.website.slice(0, 25) + (props.data.website.length > 25 ? "..." : "")
            : "-"}
        </a>
      </td>
      <td>
        <PhoneNumberCell data={props.data} />
      </td>

      <td className="">{formatFollowers(props.data.following, 1)}</td>
      <td className="">{formatFollowers(props.data.followers, 1)}</td>
    </tr>
  );
}

function PhoneNumberCell(props: any) {
  // Intenta parsear el número de teléfono si está disponible
  const parsedPhoneNumber = props.data.phone_number
    ? parsePhoneNumberFromString(`+${props.data.phone_extension}${props.data.phone_number}`)
    : null;

  // Determina qué mostrar en la celda
  const displayValue = props.data.phone_number
    ? parsedPhoneNumber && parsedPhoneNumber.isValid()
      ? parsedPhoneNumber.formatInternational()
      : props.data.phone_number
    : "-";

  return <>{displayValue}</>;
}

export default DataTable;
