import debounce from "lodash.debounce";
import React from "react";
import AsyncSelect from "react-select/async";
import { STATUS } from "../../utils/constants";
import { ArrowLeft } from "react-bootstrap-icons";
import { searchHashtags } from "../../api/mailerfind/search";
import { auth } from "../../lib/firebase/firebase";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { formatFollowers } from "../../utils/numberFormatter";

function HashtagSelect(props: any) {
  const { t } = useTranslation("HashtagSelect");

  return (
    <div className="card mb-4">
      <div className="card-body">
        <div className="row">
          <div className="col-auto pr-2">
            <button
              type="button"
              className="btn btn-ghost-secondary btn-icon btn-sm rounded-circle"
              data-bs-toggle="tooltip"
              data-bs-placement="right"
              aria-label={t("previousCustomer")}
              data-bs-original-title={t("previousCustomer")}
              onClick={props.handleBack}
              disabled={props.status !== STATUS.NOT_STARTED}
            >
              <ArrowLeft />
            </button>
          </div>
          <div className="col-auto my-auto p-0">
            <h3 className="card-header-title m-0 p-0">{t("selectHashtagTitle")}</h3>
          </div>
        </div>
        <p>{t("selectHashtagInstruction")}</p>
        <HashtagSearchBar onChange={props.onSelect} disabled={props.status !== STATUS.NOT_STARTED} />
      </div>
    </div>
  );
}

function HashtagSearchBar(props: any) {
  const { t } = useTranslation("HashtagSelect");

  const searchForHashtags = async (hashtag: any) => {
    try {
      const jwt = (await auth.currentUser?.getIdToken()) as any;
      const response = await searchHashtags(hashtag, jwt);
      return response.hashtags;
    } catch (err: any) {
      toast.error(err.message || t("searchError"));
    }
  };

  const giveResultsOfSearch = async (query: any) => {
    if (!query) return [];
    const hashtags: any = await searchForHashtags(query);
    const options = hashtags.map((hashtag: any) => {
      return {
        label: hashtag.name + " (" + formatFollowers(hashtag.media_count, 1) + " posts)",
        value: hashtag.name,
      };
    });
    return options;
  };

  const loadOptions = debounce((inputValue: string, callback: (options: any) => void) => {
    giveResultsOfSearch(inputValue).then((options: any) => {
      callback(options);
    });
  }, 1000);

  return (
    <div>
      <AsyncSelect
        loadOptions={loadOptions}
        isMulti={false}
        cacheOptions={true}
        defaultOptions={true}
        onChange={props.onChange}
        isDisabled={props.disabled || false}
      />
    </div>
  );
}

export default HashtagSelect;
