import React, { useContext, useEffect, useState } from "react";
import { NavLink, useNavigate, Navigate } from "react-router-dom";
import { Link } from "react-router-dom";
import Moment from "react-moment";
import {
  Bell,
  Calendar,
  CalendarCheck,
  Copy,
  EnvelopeCheck,
  EnvelopeOpen,
  Funnel,
  GearFill,
  HandIndex,
  Paragraph,
  PauseCircle,
  PencilSquare,
  PlayCircle,
  Plus,
  Search,
  Send,
  SendCheck,
  SortDownAlt,
  ThreeDotsVertical,
  Trash,
} from "react-bootstrap-icons";
import Header from "../../components/Header";
import toast from "react-hot-toast";
import Skeleton from "react-loading-skeleton";
import { deleteEmail, duplicateEmail, listenEmails } from "../../lib/firebase/emails";
import useEmailValidation from "../../hooks/useEmailValidation";
import IntegrationInfoModal from "../../components/IntegrationInfoModal";
import { STATUS, STATUS_TYPES } from "../../utils/constants";
import { UserContext } from "../../contexts/UserContext";
import { ProjectContext } from "../../contexts/ProjectContext";
import { useTranslation } from "react-i18next";
import { toCamelCase } from "../../utils/helpers";
import { auth } from "../../lib/firebase/firebase";
import { pauseCampaign, startCampaign } from "../../api/mailerfind/email";
import ConfirmModal from "../../components/ConfirmModal";
import DeleteConfirmationModal from "../../components/DeleteConfirmationModal";
import PremiumModal from "../../components/PremiumModal";
import EmailVerificationModal from "../../components/EmailVerificationModal";

function Table(props: any) {
  const { t } = useTranslation("Emails.index");
  const emails = props.emails;
  const [searchTerm, setSearchTerm] = useState("");
  const [deletionIsLoading, setDeletionIsLoading] = useState(false);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const filteredEmails = emails.filter(
    (email: any) =>
      email.title.toLowerCase().includes(searchTerm.toLowerCase()) || email.subject.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="card" id="emails-list-card">
      {/* Header */}
      <div className="card-header">
        <div className="row justify-content-between align-items-center flex-grow-1">
          <div className="col-sm-6 col-md-4 mb-3 mb-sm-0">
            <form>
              {/* Search */}
              <div className="input-group input-group-merge input-group-flush">
                <div className="input-group-prepend">
                  <div className="input-group-text">
                    <Search />
                  </div>
                </div>
                <input
                  id="datatableSearch"
                  type="search"
                  className="form-control"
                  placeholder={t("searchPlaceholder")}
                  aria-label={t("searchPlaceholder")}
                  onChange={handleSearchChange}
                />
              </div>
              {/* End Search */}
            </form>
          </div>
          <div className="col-sm-6">
            <div className="d-flex justify-content-sm-end align-items-center">
              <div className="dropdown d-none d-md-block mr-2">
                <button
                  className="btn btn-white dropdown-toggle"
                  type="button"
                  id="dropdownMenuButton"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <Funnel className="mr-2" />
                  <span className="align-middle">{t("status.label")}</span>
                </button>
                <div className="dropdown-menu dropdown-menu-lg-end px-2" aria-labelledby="dropdownMenuButton">
                  <div className="form-check dropdown-item px-5">
                    <input
                      type="checkbox"
                      id="formCheck0"
                      className="form-check-input"
                      checked={props.selectedStatuses.includes(STATUS.COMPLETED)}
                      onChange={() => props.handleStatusChange(STATUS.COMPLETED)}
                    />
                    <label className="form-check-label" htmlFor="formCheck0">
                      {t("status.completed")}
                    </label>
                  </div>
                  <div className="form-check dropdown-item px-5">
                    <input
                      type="checkbox"
                      id="formCheck1"
                      className="form-check-input"
                      checked={props.selectedStatuses.includes(STATUS.NOT_STARTED)}
                      onChange={() => props.handleStatusChange(STATUS.NOT_STARTED)}
                    />
                    <label className="form-check-label" htmlFor="formCheck1">
                      {t("status.notStarted")}
                    </label>
                  </div>
                  <div className="form-check dropdown-item px-5">
                    <input
                      type="checkbox"
                      id="formCheck2"
                      className="form-check-input"
                      checked={props.selectedStatuses.includes(STATUS.PAUSED)}
                      onChange={() => props.handleStatusChange(STATUS.PAUSED)}
                    />
                    <label className="form-check-label" htmlFor="formCheck2">
                      {t("status.paused")}
                    </label>
                  </div>
                  <div className="form-check dropdown-item px-5">
                    <input
                      type="checkbox"
                      id="formCheck3"
                      className="form-check-input"
                      checked={props.selectedStatuses.includes(STATUS.ERROR)}
                      onChange={() => props.handleStatusChange(STATUS.ERROR)}
                    />
                    <label className="form-check-label" htmlFor="formCheck3">
                      {t("status.error")}
                    </label>
                  </div>
                </div>
              </div>
              <div className="dropdown d-none d-md-block">
                <button
                  className="btn btn-white dropdown-toggle"
                  type="button"
                  id="dropdownMenuButton"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <SortDownAlt className="mr-2" />
                  <span className="align-middle">{t("sortBy")}</span>
                </button>
                <div className="dropdown-menu dropdown-menu-lg-end px-2" aria-labelledby="dropdownMenuButton">
                  <div className="dropdown-item" onClick={() => props.setOrderBy("title")}>
                    <Paragraph className="mr-1" /> <span className="align-middle">{t("sortOptions.title")}</span>
                  </div>
                  <div className="dropdown-item" onClick={() => props.setOrderBy("createdAt")}>
                    <Calendar className="mr-1" /> <span className="align-middle">{t("sortOptions.created")}</span>
                  </div>
                  <div className="dropdown-item" onClick={() => props.setOrderBy("sent")}>
                    <Send className="mr-1" /> <span className="align-middle">{t("sortOptions.sent")}</span>
                  </div>
                  <div className="dropdown-item" onClick={() => props.setOrderBy("opens")}>
                    <EnvelopeOpen className="mr-1" /> <span className="align-middle">{t("sortOptions.opens")}</span>
                  </div>
                  <div className="dropdown-item" onClick={() => props.setOrderBy("clicks")}>
                    <HandIndex className="mr-1" /> <span className="align-middle">{t("sortOptions.clicks")}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Row */}
      </div>
      {/* End Header */}
      {/* Table */}
      <div className="table-responsive datatable-custom" style={{ maxHeight: 600 }}>
        <div id="datatable_wrapper" className="dataTables_wrapper no-footer">
          <div id="datatable_filter" className="dataTables_filter">
            <label>
              {t("searchPlaceholder")}:
              <input type="search" aria-controls="datatable" />
            </label>
          </div>
          {!props.loading && emails.length === 0 ? (
            <NoEmails />
          ) : (
            <table className="table table-lg table-borderless table-thead-bordered table-nowrap table-align-middle card-table dataTable no-footer">
              <thead className="thead-light" style={{ position: "sticky", width: "100%", top: "-1px", zIndex: "5" }}>
                <tr>
                  <th scope="col"></th>
                  <th scope="col">{t("tableHeaders.title")}</th>
                  <th scope="col">{t("tableHeaders.stats")}</th>
                  <th scope="col">{t("tableHeaders.status")}</th>
                  <th scope="col">{t("tableHeaders.actions")}</th>
                </tr>
              </thead>
              <tbody>
                {props.loading
                  ? Array.from({ length: 3 }).map((_, index) => (
                      <TableRow
                        key={index}
                        email={{}}
                        handleDeleteEmail={props.handleShowDelete}
                        handleSendEmail={props.handleSend}
                        handleDuplicateEmail={props.handleDuplicateEmail}
                        deletionIsLoading={deletionIsLoading}
                        handleShowConfirm={props.handleShowConfirm}
                        handleShowEmailValidation={props.handleShowEmailValidation}
                        loading={props.loading}
                      />
                    ))
                  : filteredEmails.map((email: any, index: any) => (
                      <TableRow
                        key={index}
                        email={email}
                        handleDeleteEmail={props.handleShowDelete}
                        handleStartCampaign={props.handleStartCampaign}
                        handlePauseCampaign={props.handlePauseCampaign}
                        handleDuplicateEmail={props.handleDuplicateEmail}
                        deletionIsLoading={deletionIsLoading}
                        handleShowConfirm={props.handleShowConfirm}
                        handleShowEmailValidation={props.handleShowEmailValidation}
                        loading={props.loading}
                      />
                    ))}
              </tbody>
            </table>
          )}
        </div>
      </div>
      <div className="card-footer">
        {t("showing")} {filteredEmails.length} {t("results")}
      </div>
    </div>
  );
}

function TableRow(props: any) {
  const { t } = useTranslation("Emails.index");
  const { t: tViewEmail } = useTranslation("ViewEmail.index");
  const navigate = useNavigate();
  const email = props.email;
  return (
    <tr role="row" className="odd">
      <td className="table-column-pr-0">
        <div className="custom-control custom-checkbox">
          <input type="checkbox" className="custom-control-input" />
          <label className="custom-control-label" />
        </div>
      </td>
      <td className="table-column-pl-0">
        {props.loading ? (
          <Skeleton width={250} />
        ) : (
          <Link className="d-flex align-items-center" to={`/emails/${email.id}`}>
            <div className="ml-3">
              <span className="d-block h5 text-hover-primary mb-0">
                {email?.followUp?.isAFollowUpEmail === true && (
                  <>
                    <CalendarCheck className="align-middle mr-1" />
                    <Bell className="align-middle mr-1" />
                  </>
                )}
                {email.title}
              </span>
              <span className="d-block font-size-sm text-body">
                {t("badges.created")} <Moment fromNow>{email.createdAt.toDate()}</Moment>
              </span>
            </div>
          </Link>
        )}
      </td>
      <td>
        {props.loading ? (
          <Skeleton width={170} />
        ) : (
          <>
            <span className="badge badge-primary mr-1">
              {email?.sent || 0} {t("badges.sent")}
            </span>
            <span className="badge badge-success mr-1">
              {email?.opens || 0} {t("badges.opens")}
            </span>
            <span className="badge badge-warning mr-1">
              {(((email?.opens || 0) / (email?.sent || 1)) * 100).toFixed(0)}% {t("badges.openRate")}
            </span>
            <span className="badge badge-info mr-1">
              {email?.uniqueClicks || email?.clicks || 0} {t("badges.clicks")}
            </span>
          </>
        )}
      </td>
      <td>
        {props.loading ? (
          <Skeleton width={100} />
        ) : (
          <>
            {STATUS_TYPES.map(({ status, color }: any) => {
              if (email.status === status) {
                return (
                  <React.Fragment key={status}>
                    <span key={status} className={`legend-indicator ${color}`} />
                    {t(`status.${toCamelCase(status)}`).toUpperCase()}
                  </React.Fragment>
                );
              }
              return null;
            })}
          </>
        )}
      </td>
      <td>
        {props.loading ? (
          <Skeleton width={300} />
        ) : (
          <>
            <div className="dropdown">
              <button
                className="btn btn-white"
                type="button"
                id="dropdownMenuButton"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <ThreeDotsVertical />
              </button>
              <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                {["NOT_STARTED", "PAUSED", "ERROR"].includes(email.status) && (
                  <>
                    <span
                      className="dropdown-item"
                      style={{ cursor: "pointer" }}
                      onClick={() => props.handleShowConfirm(email.id, "start")}
                    >
                      <PlayCircle className="mr-1" />
                      <span className="align-middle">{t("startSending")}</span>
                    </span>
                    <div className="dropdown-divider"></div>
                  </>
                )}
                {["RUNNING"].includes(email.status) && (
                  <>
                    <span
                      className="dropdown-item"
                      style={{ cursor: "pointer" }}
                      onClick={() => props.handleShowConfirm(email.id, "pause")}
                    >
                      <PauseCircle className="mr-1" />
                      <span className="align-middle">{t("pauseSending")}</span>
                    </span>
                    <div className="dropdown-divider"></div>
                  </>
                )}

                {["STARTING"].includes(email.status) && (
                  <>
                    <span className="dropdown-item disabled" style={{ cursor: "progress" }}>
                      <span>
                        <PlayCircle className="mr-1" />
                        <span className="align-middle">{t("startSending")}</span>
                      </span>
                    </span>
                    <div className="dropdown-divider"></div>
                  </>
                )}

                <span className="dropdown-item" style={{ cursor: "pointer" }} onClick={() => navigate(`/emails/${email.id}/edit`)}>
                  <PencilSquare className="mr-1" /> <span className="align-middle">{t("editButton")}</span>
                </span>
                <span className="dropdown-item" style={{ cursor: "pointer" }} onClick={() => props.handleDuplicateEmail(email.id)}>
                  <Copy className="mr-1" /> <span className="align-middle">{t("cloneButton")}</span>
                </span>
                <span className="dropdown-item" style={{ cursor: "pointer" }} onClick={() => props.handleShowEmailValidation(email)}>
                  <EnvelopeCheck className="mr-1" /> <span className="align-middle">{tViewEmail("seeEmailValidationResults")}</span>
                </span>
                <div className="dropdown-divider"></div>
                <span className="dropdown-item text-danger" style={{ cursor: "pointer" }} onClick={() => props.handleDeleteEmail(email.id)}>
                  <Trash className="mr-1" /> <span className="align-middle">{t("deleteButton")}</span>
                </span>
              </div>
            </div>
          </>
        )}
      </td>
    </tr>
  );
}

function NoEmails() {
  const { t } = useTranslation("Emails.index");
  return (
    <div className="card-body py-4">
      <div className="row align-items-center py-4">
        <div className="col text-center">
          <img src="/assets/svg/illustrations/sending.svg" alt="Empty" className="img-fluid mb-4" style={{ maxWidth: "10rem" }} />
          <p className="mb-0 text-center mb-4">{t("noEmails.message")}</p>
          <Link to="/emails/new" className="btn btn-primary">
            <span className="mr-1">
              <Plus size="20" />
            </span>
            <span className="align-middle">{t("noEmails.addButton")}</span>
          </Link>
        </div>
      </div>
    </div>
  );
}

function Emails() {
  const { t } = useTranslation("Emails.index");
  const [emails, setEmails] = useState([]) as any;
  const [emailsAreLoading, setEmailsAreLoading] = useState(true);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [emailToDelete, setEmailToDelete] = useState(null) as any;
  const [orderBy, setOrderBy] = useState("createdAt");
  const [selectedStatuses, setSelectedStatuses] = useState([]);
  const [confirmIsLoading, setConfirmIsLoading] = useState(false);
  const [showPremiumModal, setShowPremiumModal] = useState(false);
  const [showEmailVerificationModal, setShowEmailVerificationModal] = useState(false);
  const [selectedEmailForValidation, setSelectedEmailForValidation] = useState<any>(null);
  const { user } = useContext(UserContext) as any;
  const { project } = useContext(ProjectContext) as any;
  const { 
    redirectToValidation, 
    startEmailValidation,
    showIntegrationModal,
    setShowIntegrationModal,
    selectedIntegration 
  } = useEmailValidation(
    user?.firebaseUser?.uid || "",
    project?.id || null,
    "Emails.index"
  );

  // Estado y funciones para el modal de confirmación de campaña
  const [confirmModal, setConfirmModal] = useState<{ action: "start" | "pause" | null; emailId: string | null; show: boolean }>({
    action: null,
    emailId: null,
    show: false,
  });

  const handleShowConfirm = (emailId: string, action: "start" | "pause") => {
    setConfirmModal({ emailId, action, show: true });
  };

  const handleConfirmAction = async () => {
    if (!confirmModal.emailId || !confirmModal.action) return;
    if (user.firebaseUser.plan === "free") {
      setConfirmModal({ emailId: null, action: null, show: false });
      setShowPremiumModal(true);
      return;
    }
    setConfirmIsLoading(true);
    try {
      if (confirmModal.action === "start") {
        await handleStartCampaign(confirmModal.emailId);
      } else if (confirmModal.action === "pause") {
        await handlePauseCampaign(confirmModal.emailId);
      }
    } catch (error) {
      // Gestión de error (opcional)
    } finally {
      setConfirmIsLoading(false);
      setConfirmModal({ action: null, emailId: null, show: false });
    }
  };



  useEffect(() => {
    const unsubscribe = listenEmails(
      user.firebaseUser.uid,
      orderBy,
      { status: selectedStatuses, project: project?.id || null },
      (emails) => {
        setEmails(emails);
        setEmailsAreLoading(false);
      },
      (error) => {
        console.error("Error fetching emails in real time:", error);
        setEmailsAreLoading(false);
      }
    );

    return () => unsubscribe(); // Limpiar la suscripción al desmontar el componente
  }, [orderBy, selectedStatuses, project]);

  const handleDeleteEmail = async (id: any) => {
    try {
      await deleteEmail(user.firebaseUser.uid, id);
    } catch (err: any) {
      toast.error(err.message);
    }
  };

  const handleDuplicate = async (id: any) => {
    // This function duplicates an email
    await duplicateEmail(user.firebaseUser.uid, id);
    toast.success("Email duplicated");
  };

  const handleStartCampaign = async (id: string) => {
    try {
      const jwt = await auth.currentUser!.getIdToken();
      await startCampaign(id, jwt);
    } catch (err: any) {
      toast.error(err.message);
    }
  };

  const handlePauseCampaign = async (id: string) => {
    try {
      const jwt = await auth.currentUser!.getIdToken();
      await pauseCampaign(id, jwt);
    } catch (err: any) {
      toast.error(err.message);
    }
  };

  const handleCloseDelete = () => {
    setShowDeleteModal(false);
    setEmailToDelete(null);
  };

  const handleShowDelete = (emailId: any) => {
    setEmailToDelete(emailId);
    setShowDeleteModal(true);
  };

  /**
   * Show email validation modal for a specific email
   */
  const handleShowEmailValidation = (email: any) => {
    setSelectedEmailForValidation(email);
    setShowEmailVerificationModal(true);
  };

  const handleStatusChange = (status: string) => {
    setSelectedStatuses((prev: any) => {
      // Verifica si el estado ya está en el arreglo
      const isStatusSelected = prev.includes(status);
      if (isStatusSelected) {
        // Si ya está seleccionado, lo elimina
        return prev.filter((s: any) => s !== status);
      } else {
        // Si no está seleccionado, lo añade
        return [...prev, status];
      }
    });
  };

  // If we have a redirect path, navigate to it
  if (redirectToValidation) {
    return <Navigate to={redirectToValidation} />;
  }

  return (
    <div>
      <Header title={t("pageTitle")} subtitle={t("pageSubtitle")} breadCrumbItems={[{ href: "/emails", title: "Emails" }]}>
        <NavLink className="btn btn-white mr-2" to="/emails/settings">
          <GearFill className="mr-1 mb-1" /> {t("settings")}
        </NavLink>
        <NavLink className="btn btn-white mr-2" to="/senders">
          <SendCheck className="mr-1 mb-1" /> {t("manageSenders")}
        </NavLink>
        <NavLink className="btn btn-primary" to="/emails/new" id="new-email-btn">
          <Plus size="20" className="mr-1" />
          <span className="align-middle">{t("newEmail")}</span>
        </NavLink>
      </Header>
      <Table
        emails={emails}
        setOrderBy={setOrderBy}
        handleStatusChange={handleStatusChange}
        selectedStatuses={selectedStatuses}
        handleShowConfirm={handleShowConfirm}
        handleDuplicateEmail={handleDuplicate}
        handleShowDelete={handleShowDelete}
        handleShowEmailValidation={handleShowEmailValidation}
        loading={emailsAreLoading}
      />
      <ConfirmModal
        show={confirmModal.show}
        setShow={(show: boolean) => setConfirmModal({ ...confirmModal, show })}
        onConfirm={handleConfirmAction}
        text={confirmModal.action === "start" ? t("confirmStart") : confirmModal.action === "pause" ? t("confirmPause") : ""}
        loading={confirmIsLoading}
      />

      <DeleteConfirmationModal
        show={showDeleteModal}
        close={handleCloseDelete}
        onConfirm={handleDeleteEmail}
        data={emailToDelete}
        text={t("confirmMessage")}
      />

      <PremiumModal show={showPremiumModal} setShow={setShowPremiumModal} />

      <EmailVerificationModal
        show={showEmailVerificationModal}
        close={() => setShowEmailVerificationModal(false)}
        onVerify={async (integration) => {
          await startEmailValidation(selectedEmailForValidation?.analysis || "", integration);
        }}
        analysisId={selectedEmailForValidation?.analysis || ""}
        userId={user?.firebaseUser?.uid || ""}
      />

      <IntegrationInfoModal 
        show={showIntegrationModal} 
        setShow={setShowIntegrationModal} 
        integrationType={selectedIntegration} 
      />
    </div>
  );
}

export default Emails;
