// LiveEventCard.tsx
import React from "react";
import moment from "moment-timezone";
import { BoxArrowUpRight } from "react-bootstrap-icons";
import { LiveEvent } from "../../types/EventTypes";

interface LiveEventCardProps {
  liveEvent: LiveEvent;
}

const LiveEventCard: React.FC<LiveEventCardProps> = ({ liveEvent }) => {
  const getNextOccurrence = (day: number, time: string, eventTimeZone: string): moment.Moment => {
    const [hour, minute] = time.split(":").map(Number);
    // Obtenemos el momento actual en la zona del evento
    const now = moment.tz(eventTimeZone);
    // Creamos una fecha con la hora del evento para hoy en la zona del evento
    let eventDate = now.clone().hour(hour).minute(minute).second(0).millisecond(0);
    // Usamos isoWeekday para que lunes=1, martes=2, ... domingo=7
    let diff = day - now.isoWeekday();
    if (diff < 0 || (diff === 0 && eventDate.isBefore(now))) {
      diff += 7;
    }
    eventDate.add(diff, "days");
    return eventDate;
  };

  // Calculamos la próxima ocurrencia usando los datos del evento
  const nextOccurrence = getNextOccurrence(liveEvent.recurrence.day, liveEvent.recurrence.time, liveEvent.recurrence.timeZone);

  // Obtenemos la zona horaria del usuario y convertimos la fecha
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const localTime = nextOccurrence.clone().tz(userTimeZone);
  // Formateamos la fecha de forma legible, por ejemplo "23 abr. 2025 17:00"
  const formattedDateTime = localTime.format("lll");

  return (
    <div className="col-md-4 mb-4">
      <div className="card h-100">
        <a href={liveEvent.src} target="_blank" rel="noreferrer">
          <img src={liveEvent.image} className="card-img-top" alt={liveEvent.title} />
        </a>
        <div className="card-body d-flex flex-column">
          <small className="card-subtitle text-muted">{liveEvent.category}</small>
          <h3 className="card-title mb-3">{liveEvent.title}</h3>
          <div className="row mb-2 justify-content-between">
            <div className="col my-auto">
              <div className="avatar-group avatar-group-sm">
                <a className="avatar avatar-sm avatar-circle" data-toggle="tooltip" data-placement="top" title="Emily Milda" href="#">
                  <img className="avatar-img" src="/assets/img/nico.jpg" alt="Emily Milda" />
                </a>
                {/*<a className="avatar avatar-sm avatar-circle" data-toggle="tooltip" data-placement="top" title="John O'nolan" href="#">
                  <img className="avatar-img" src="/assets/img/160x160/img3.jpg" alt="John O'nolan" />
                </a>*/}
              </div>
            </div>
            <div className="col-auto my-auto">
              <span>{formattedDateTime}</span>
            </div>
          </div>
          <p className="card-text">{liveEvent.description}</p>
          <a href={liveEvent.src} className="btn btn-primary w-100 mt-auto" target="_blank" rel="noreferrer">
            Inscribirse <BoxArrowUpRight size={10} className="ml-1 mb-1" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default LiveEventCard;
