import React, { useContext, useState, useEffect } from "react";
import {
  ArrowUpCircle,
  BoxArrowRight,
  BoxArrowUpRight,
  ChatDotsFill,
  Instagram,
  LifePreserver,
  LightningChargeFill,
  List,
  Nut,
  NutFill,
  PeopleFill,
  PersonFill,
  PersonFillGear,
  Plus,
  RocketTakeoff,
  Sliders,
} from "react-bootstrap-icons";
import { Crisp } from "crisp-sdk-web";
import { getCurrentPeriodCreditsUsed } from "../api/mailerfind/users";
import { UserContext } from "../contexts/UserContext";
import { auth } from "../lib/firebase/firebase";
import PremiumModal from "./PremiumModal";
import { Link, useNavigate } from "react-router-dom";
import BuyCreditsModal from "./BuyCreditsModal";
import ImpersonateModal from "./ImpersonateModal";
import toast from "react-hot-toast";
import { getProjects } from "../lib/firebase/projects";
import { ProjectContext } from "../contexts/ProjectContext";
import ManageProjectsModal from "./ManageProjectsModal";
import NewProjectModal from "./NewProjectModal";
import { useTranslation } from "react-i18next";
import { signOut } from "../lib/firebase/auth";

function Navbar({ setSidebarOpen }: any) {
  const { t } = useTranslation("Component.Navbar");
  const [profilePicture] = useState() as any;
  const { user } = useContext(UserContext) as any;
  const { project, setProject } = useContext(ProjectContext) as any;
  const [projects, setProjects] = useState([]) as any;
  const navigate = useNavigate();

  const [currentPeriodRequests, setCurrentPeriodRequests] = useState(0);
  const [showPremiumModal, setShowPremiumModal] = useState(false);
  const [showBuyCreditsModal, setShowBuyCreditsModal] = useState(false);

  const [showImpersonateUserModal, setShowImpersonateUserModal] = useState(false);
  const [showNewProjectModal, setShowNewProjectModal] = useState(false);
  const [showManageProjectsModal, setShowManageProjectsModal] = useState(false);

  useEffect(() => {
    if (!user.firebaseUser) return;
    getCurrentPeriodRequests();
  }, [user.firebaseUser]);

  useEffect(() => {
    if (!user.firebaseUser) return;
    getProjectList();
  }, [auth, project]);

  const getCurrentPeriodRequests = async () => {
    const jwt = await auth.currentUser!.getIdToken();
    const response: any = await getCurrentPeriodCreditsUsed(jwt);
    if (!response.currentPeriodRequests) return;
    setCurrentPeriodRequests(response.currentPeriodRequests);
  };

  const getProjectList = async () => {
    try {
      const projects = await getProjects(user.firebaseUser.uid);
      setProjects(projects);
    } catch (err: any) {
      toast.error(t("errorFetchingProjects"));
    }
  };

  const handleLogout = async () => {
    await signOut();
  };

  const handleUpgrade = () => {
    if (user.firebaseUser.plan === "free") {
      setShowPremiumModal(true);
      return;
    }

    navigate("/upgrade");
  };

  const toggleSidebar = () => {
    setSidebarOpen((prevState: boolean) => !prevState);
  };

  const handleOpenSupport = () => {
    Crisp.chat.open();
  };

  return (
    <header className="navbar navbar-expand-lg navbar-spacer-y navbar-light sticky-top" id="header" style={{ zIndex: 99 }}>
      <div className="container-fluid">
        <div className="navbar-nav-wrap">
          <div className="navbar-brand-wrapper">
            {/* Logo */}
            <List className="text-secondary mr-2" size={26} onClick={toggleSidebar} />
            <Link className="navbar-brand" to="/" aria-label="Mailerfind">
              <img className="navbar-brand-logo" src="/assets/img/logos/mailerfind.png" alt="Logo" />
            </Link>
            {/* End Logo */}
          </div>

          <div className="dropdown d-none d-md-block">
            <button
              className="btn btn-outline-white border btn-sm dropdown-toggle"
              type="button"
              id="dropdownMenuButton"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              {(project && (
                <>
                  <NutFill className="mr-2" />
                  <span>{project.name}</span>
                </>
              )) || (
                <>
                  <NutFill className="mr-2" />
                  <span>{t("defaultProject")}</span>
                </>
              )}
            </button>
            <div className="dropdown-menu px-2" aria-labelledby="dropdownMenuButton">
              <span className="dropdown-header" style={{ fontSize: ".65625rem" }}>
                Settings
              </span>
              <div className="dropdown-item" onClick={() => setShowNewProjectModal(true)}>
                <Plus className="mr-1" />
                <span className="align-middle">Create New Project</span>
              </div>
              <div className="dropdown-item" onClick={() => setShowManageProjectsModal(true)}>
                <Sliders className="mr-2" />
                <span className="align-middle">Manage Projects</span>
              </div>
              <div className="dropdown-divider"></div>
              <span className="dropdown-header" style={{ fontSize: ".65625rem" }}>
                Projects
              </span>
              <div className="dropdown-item" onClick={() => setProject(null)}>
                {project === null ? <NutFill className="mr-1" /> : <Nut className="mr-1" />}
                <span className="align-middle">{t("defaultProject")}</span>
              </div>
              {projects &&
                projects.map((_project: any, index: any) => {
                  return (
                    <div className="dropdown-item" onClick={() => setProject(_project)} key={index}>
                      {_project?.id === project?.id ? <NutFill className="mr-1" /> : <Nut className="mr-1" />}

                      <span className="align-middle">{_project.name}</span>
                    </div>
                  );
                })}
              <NewProjectModal show={showNewProjectModal} setShow={setShowNewProjectModal} />
              <ManageProjectsModal show={showManageProjectsModal} setShow={setShowManageProjectsModal} />
            </div>
          </div>

          {/* Secondary Content */}
          <div className="navbar-nav-wrap-content-right d-flex">
            {/* Navbar */}

            <ul className="navbar-nav align-items-center flex-row">
              {user?.firebaseUser?.plan !== "unlimited" ? (
                <>
                  {user?.firebaseUser?.plan === "free" ? (
                    <>
                      <button className="btn btn-gradient btn-sm mr-3 align-middle" onClick={handleUpgrade}>
                        <ArrowUpCircle className="mr-1 align-middle" /> <span className="align-middle">{t("upgrade")}</span>
                      </button>
                    </>
                  ) : (
                    <>
                      <button className="btn btn-outline-primary btn-sm mr-3 align-middle" onClick={() => setShowBuyCreditsModal(true)}>
                        <LightningChargeFill className="mr-1 align-middle" /> <span className="align-middle">{t("buyCredits")}</span>
                      </button>
                    </>
                  )}
                </>
              ) : (
                <>
                  {user?.firebaseUser?.analysis?.speed !== "ultra_fast" && (
                    <Link type="button" to="/product/speed-boost" className="btn btn-gradient btn-sm mr-3">
                      <span className="mr-2 align-middle">{t("speedBoost")}</span>
                      <RocketTakeoff />
                    </Link>
                  )}
                </>
              )}

              <li className="nav-item">
                {/* Account */}
                <div className="hs-unfold">
                  <a
                    className="js-hs-unfold-invoker navbar-dropdown-account-wrapper hs-mega-menu-invoker"
                    href="#"
                    id="withMegaMenuDropdownEg"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <div className="avatar avatar-sm avatar-circle">
                      <img className="avatar-img" src={profilePicture || "/assets/img/160x160/img1.jpg"} alt="Profile" />
                      <span className="avatar-status avatar-sm-status avatar-status-success" />
                    </div>
                  </a>
                  {/* Dropdown */}
                  <div className="hs-has-sub-menu dropdown ">
                    <div
                      className="hs-sub-menu dropdown-menu dropdown-menu-lg-right px-2"
                      aria-labelledby="withMegaMenuDropdownEg"
                      style={{ minWidth: 250 }}
                    >
                      <div className="dropdown-item-text mb-2">
                        <div className="media">
                          <div className="avatar avatar-sm avatar-circle mr-2 mt-1">
                            <img className="avatar-img" src={"/assets/img/160x160/img1.jpg"} alt="Mailerfind Account Profile" />
                          </div>
                          <div className="media-body">
                            <span className="card-title h5">
                              <Link to="/my-account" className="text-dark">
                                {user?.firebaseUser?.name || user?.firebaseUser?.firstname
                                  ? `${user?.firebaseUser?.firstname || user?.firebaseUser?.name} ${user?.firebaseUser?.surname}`
                                  : user?.firebaseUser?.email}
                              </Link>
                            </span>
                            <span className="card-text">{user?.firebaseUser?.email}</span>
                            {user?.firebaseUser?.monthlyAnalysisLimit !== -1 && (
                              <div className="progress mt-2">
                                <div
                                  className={`progress-bar ${
                                    currentPeriodRequests >= user?.firebaseUser?.monthlyAnalysisLimit ? "bg-danger" : "bg-success"
                                  }`}
                                  role="progressbar"
                                  style={{
                                    width: `${(currentPeriodRequests / user?.firebaseUser?.monthlyAnalysisLimit) * 100}%`,
                                  }}
                                  aria-valuenow={50}
                                  aria-valuemin={0}
                                  aria-valuemax={100}
                                />
                              </div>
                            )}
                          </div>
                        </div>
                        {user?.firebaseUser?.plan !== "unlimited" && (
                          <button className="btn btn-xs btn-block btn-outline-primary mt-3" onClick={handleUpgrade}>
                            <ArrowUpCircle className="mr-1 align-middle" /> <span className="align-middle">{t("upgrade")}</span>
                          </button>
                        )}
                      </div>
                      <div className="dropdown-divider" />
                      <Link className="dropdown-item" to="/my-account">
                        <PersonFill className="mr-2" size={18} />
                        {t("myAccount")}
                      </Link>
                      {(user.firebaseUser.role === "admin" || user.firebaseUser.role === "support") && (
                        <>
                          <div className="dropdown-item" style={{ cursor: "pointer" }} onClick={() => setShowImpersonateUserModal(true)}>
                            <PersonFillGear className="mr-2" size={18} />
                            {t("impersonateAccount")}
                          </div>
                          <ImpersonateModal show={showImpersonateUserModal} setShow={setShowImpersonateUserModal} />
                        </>
                      )}

                      <Link className="dropdown-item" to="/invitations">
                        <PeopleFill className="mr-2" size={18} />
                        {t("inviteMembers")}
                      </Link>

                      <div className="dropdown-divider" />
                      <a className="dropdown-item" href="https://help.mailerfind.com/" target="_blank" rel="noopener noreferrer">
                        <LifePreserver className="mr-2" />
                        {t("helpCenter")}
                      </a>
                      <div className="dropdown-item" onClick={() => handleOpenSupport()}>
                        <ChatDotsFill className="mr-2" />
                        {t("support")}
                      </div>
                      <div className="dropdown-divider" />
                      <a
                        className="dropdown-item"
                        href="https://instagram.com"
                        target="_blank"
                        style={{ cursor: "pointer" }}
                        rel="noopener noreferrer"
                      >
                        <Instagram className="mr-2" /> <span className="mr-1 align-middle">{t("openInstagram")}</span>
                        <BoxArrowUpRight size={10} className="mb-1" />
                      </a>
                      <div className="dropdown-item" onClick={handleLogout} style={{ cursor: "pointer" }}>
                        <BoxArrowRight className="mr-2" /> {t("logOut")}
                      </div>
                    </div>
                  </div>
                  {/* End Dropdown */}
                </div>
                {/* End Account */}
              </li>
            </ul>
            {/* End Navbar */}
          </div>
          {/* End Secondary Content */}
        </div>
      </div>
      <PremiumModal show={showPremiumModal} setShow={setShowPremiumModal} title={t("upgradePlan")} />
      <BuyCreditsModal show={showBuyCreditsModal} setShow={setShowBuyCreditsModal} />
    </header>
  );
}

export default Navbar;
