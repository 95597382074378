import React, { useState, useEffect, useContext } from "react";
import { useParams, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Loading from "../../components/Loading";
import Header from "../../components/Header";
import { UserContext } from "../../contexts/UserContext";
import toast from "react-hot-toast";
import { listenAnalysis } from "../../lib/firebase/analysis";
import { listenValidation, listenLatestValidation } from "../../lib/firebase/validations";
import { capitalizeString } from "../../utils/helpers";
import { Pie } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { ExclamationTriangleFill } from "react-bootstrap-icons";

// Register the chart.js components
ChartJS.register(ArcElement, Tooltip, Legend);

/**
 * EmailValidation component displays the results of an email validation
 * It fetches the validation data from Firestore and displays it in a user-friendly way
 */
function EmailValidation() {
  const { t } = useTranslation("EmailValidation.index");
  const { id, analysisId } = useParams() as any;
  const [loading, setLoading] = useState(true);
  const { user } = useContext(UserContext) as any;

  // State for validation data
  const [validation, setValidation] = useState<any>(null);
  const [analysis, setAnalysis] = useState<any>(null);

  // Set up real-time listening for validation data
  useEffect(() => {
    if (!user?.firebaseUser?.uid) return;

    let unsubscribe: () => void;
    setLoading(true);

    const handleValidationUpdate = (validationData: any) => {
      if (validationData) {
        setValidation(validationData);
      } else {
        toast.error(t("validationNotFound"));
      }
      setLoading(false);
    };

    const handleError = (error: Error) => {
      toast.error(error.message || t("errorFetchingValidation"));
      setLoading(false);
    };

    if (id) {
      // If ID is provided, listen to that specific validation
      unsubscribe = listenValidation(user.firebaseUser.uid, id, handleValidationUpdate, handleError);
    } else {
      // If no ID is provided, listen to the most recent validation
      unsubscribe = listenLatestValidation(user.firebaseUser.uid, handleValidationUpdate, handleError);
    }

    // Clean up listener on unmount
    return () => {
      if (unsubscribe) unsubscribe();
    };
  }, [id, user?.firebaseUser?.uid, t]);

  // Fetch analysis data when validation is loaded
  useEffect(() => {
    if (!user?.firebaseUser?.uid) return;

    // Use the analysisId from the URL params if available, otherwise use the one from validation
    const analysisIdToUse =
      analysisId ||
      (validation && validation.analysis ? (typeof validation.analysis === "string" ? validation.analysis : validation.analysis.id) : null);

    if (!analysisIdToUse) {
      if (validation) {
        // Only show error if we have a validation but no analysis ID
        toast.error(t("invalidAnalysisId"));
      }
      return;
    }

    const unsubscribe = listenAnalysis(
      user.firebaseUser.uid,
      analysisIdToUse,
      (analysisData) => {
        setAnalysis(analysisData);
      },
      (error) => toast.error(error.message)
    );

    return () => unsubscribe();
  }, [validation, user?.firebaseUser?.uid, t, analysisId]);

  if (loading) return <Loading />;
  if (!validation) {
    return (
      <div className="row justify-content-center align-items-sm-center py-sm-10">
        <div className="col-9 col-sm-6 col-lg-4">
          <div className="text-center text-sm-end me-sm-4 mb-5 mb-sm-0">
            <img src="/assets/svg/illustrations/oc-thinking.svg" className="img-fluid" alt={t("thinkingAlt")} />
          </div>
        </div>
        <div className="col-sm-6 col-lg-4 text-center text-sm-start">
          <h1 className="display-1 mb-0">404</h1>
          <p className="lead">
            {t("oops")}, {t("validationNotFound")}
          </p>
          <Link className="btn btn-primary" to={analysisId ? `/analysis/${analysisId}` : "/analysis-manager"}>
            {t("goBack")}
          </Link>
        </div>
      </div>
    );
  }

  return (
    <>
      <Header
        title={t("emailValidationResults")}
        subtitle={analysis?.name || t("emailValidation")}
        breadCrumbItems={[
          { title: t("analysis"), href: "/analysis-manager" },
          { title: analysis?.name || t("analysis"), href: `/analysis/${analysis?.id}` },
          { title: t("emailValidation") },
        ]}
      />

      {validation.status === "error" && (
        <div className="alert alert-soft-danger" role="alert">
          <ExclamationTriangleFill /> {t("validationError")}: {validation.errorMessage || t("unknownError")}
        </div>
      )}

      <div className="row">
        <div className="col-md-6 mb-4">
          <div className="card h-100">
            <div className="card-header">
              <h5 className="card-header-title">{t("validationSummary")}</h5>
            </div>
            <div className="card-body">
              <div className="row align-items-center mb-4">
                <div className="col">
                  <h6 className="card-subtitle text-muted">{t("provider")}</h6>
                  <h2 className="card-title">{capitalizeString(validation.provider)}</h2>
                </div>
                <div className="col-auto">
                  <span className={`badge bg-soft-${getBadgeColor(validation.status)} text-${getBadgeColor(validation.status)}`}>
                    {getStatusLabel(validation.status, t)}
                  </span>
                </div>
              </div>

              <div className="row">
                <div className="col-md-6 mb-4">
                  <div className="card card-bordered shadow-none">
                    <div className="card-body text-center">
                      <h3 className="text-primary">{validation.statistics?.total || 0}</h3>
                      <p className="mb-0">{t("totalEmails")}</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 mb-4">
                  <div className="card card-bordered shadow-none">
                    <div className="card-body text-center">
                      <h3 className="text-success">{validation.statistics?.valid || 0}</h3>
                      <p className="mb-0">{t("validEmails")}</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 mb-4">
                  <div className="card card-bordered shadow-none">
                    <div className="card-body text-center">
                      <h3 className="text-danger">{validation.statistics?.invalid || 0}</h3>
                      <p className="mb-0">{t("invalidEmails")}</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 mb-4">
                  <div className="card card-bordered shadow-none">
                    <div className="card-body text-center">
                      <h3 className="text-warning">{validation.statistics?.unknown || 0}</h3>
                      <p className="mb-0">{t("unknownEmails")}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-flex align-items-center justify-content-between">
                {validation.createdAt && (
                  <div className="mt-2">
                    <small className="text-muted">
                      {t("startedAt")}: {new Date(validation.createdAt.seconds * 1000).toLocaleString()}
                    </small>
                  </div>
                )}

                {validation.finishedAt && (
                  <div>
                    <small className="text-muted">
                      {t("finishedAt")}: {new Date(validation.finishedAt.seconds * 1000).toLocaleString()}
                    </small>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="col-md-6 mb-4">
          <div className="card h-100">
            <div className="card-header">
              <h5 className="card-header-title">{t("validationChart")}</h5>
            </div>
            <div className="card-body d-flex align-items-center justify-content-center">
              {validation.status === "processing" ? (
                <div className="text-center py-4">
                  <div className="spinner-border text-primary mb-3" role="status">
                    <span className="visually-hidden"></span>
                  </div>
                  <p>{t("validationInProgress")}</p>
                </div>
              ) : validation.status === "error" ? (
                <div className="text-center py-4">
                  <div className="text-danger mb-3">
                    <ExclamationTriangleFill style={{ fontSize: "2rem" }} />
                  </div>
                  <p className="text-danger">{t("validationError")}</p>
                  {validation.errorMessage && <p className="small text-muted">{validation.errorMessage}</p>}
                </div>
              ) : (
                <div className="w-100 h-100 d-flex align-items-center justify-content-center">
                  {validation.statistics?.total > 0 ? (
                    <div style={{ width: "100%", height: "250px" }}>
                      <Pie
                        data={{
                          labels: [t("validEmails"), t("invalidEmails"), t("unknownEmails")],
                          datasets: [
                            {
                              data: [
                                validation.statistics?.valid || 0,
                                validation.statistics?.invalid || 0,
                                validation.statistics?.unknown || 0,
                              ],
                              backgroundColor: [
                                "rgba(75, 192, 192, 0.2)", // Valid (teal)
                                "rgba(255, 99, 132, 0.2)", // Invalid (pink)
                                "rgba(255, 206, 86, 0.2)", // Unknown (yellow)
                              ],
                              borderColor: ["rgba(75, 192, 192, 1)", "rgba(255, 99, 132, 1)", "rgba(255, 206, 86, 1)"],
                              borderWidth: 1,
                            },
                          ],
                        }}
                        options={{
                          responsive: true,
                          maintainAspectRatio: false,
                          plugins: {
                            legend: {
                              position: "bottom",
                              labels: {
                                padding: 20,
                                usePointStyle: true,
                                pointStyle: "circle",
                              },
                            },
                            tooltip: {
                              callbacks: {
                                label: function (context) {
                                  const label = context.label || "";
                                  const value = (context.raw as number) || 0;
                                  const total = (context.dataset.data as number[]).reduce((a: number, b: number) => a + b, 0);
                                  const percentage = Math.round((value / total) * 100);
                                  return `${label}: ${value} (${percentage}%)`;
                                },
                              },
                            },
                          },
                        }}
                      />
                    </div>
                  ) : (
                    <div className="text-center py-4">
                      <p>{t("noDataAvailable")}</p>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="card">
        <div className="card-header">
          <h5 className="card-header-title">{t("analysisDetails")}</h5>
        </div>
        <div className="card-body">
          {analysis ? (
            <div className="row">
              <div className="col-md-4 mb-4 mb-lg-0">
                <h6 className="text-muted">{t("analysisName")}</h6>
                <p>{analysis.name}</p>
              </div>
              <div className="col-md-4 mb-4 mb-lg-0">
                <h6 className="text-muted">{t("totalProspects")}</h6>
                <p>{analysis.prospectsCount}</p>
              </div>
              <div className="col-md-4 mb-4 mb-lg-0">
                <h6 className="text-muted">{t("emailsFound")}</h6>
                <p>{analysis.emailsFoundCount}</p>
              </div>
            </div>
          ) : (
            <div className="text-center py-4">
              <div className="spinner-border text-primary" role="status">
                <span className="visually-hidden">{t("loading")}</span>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

/**
 * Get the appropriate badge color based on validation status
 * @param status - The validation status
 * @returns The Bootstrap color class
 */
function getBadgeColor(status: string): string {
  switch (status) {
    case "processing":
      return "warning";
    case "error":
      return "danger";
    case "completed":
      return "success";
    default:
      return "secondary";
  }
}

/**
 * Get the appropriate status label based on validation status
 * @param status - The validation status
 * @param t - The translation function
 * @returns The translated status label
 */
function getStatusLabel(status: string, t: any): string {
  switch (status) {
    case "processing":
      return t("processing");
    case "error":
      return t("error");
    case "completed":
      return t("completed");
    default:
      return status;
  }
}

export default EmailValidation;
